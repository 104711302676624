import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "mDanmaku",
  props: {
    config: {
      typeof: Object,
      default: () => {}
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    danmakuConfig() {
      return {
        ...this.mConfig,
        ...this.config
      };
    }
  },
  data() {
    return {
      danmus: [],
      mConfig: {
        channels: 2,
        // 轨道数量，为0则弹幕轨道数会撑满容器
        useSlot: true,
        // 是否开启slot
        loop: true,
        // 是否开启弹幕循环
        speeds: 130,
        // 弹幕速度，实际为每秒弹幕走过的像素距离
        fontSize: 20,
        // 文本模式下的字号
        top: 10,
        // 弹幕轨道间的垂直间距
        right: 0,
        // 同一轨道弹幕的水平间距
        debounce: 80,
        // 弹幕刷新频率（多少毫秒插入一条弹幕，建议不小于50）
        randomChannel: true
      },
      danmakuPage: {
        total: 0,
        page: 1,
        limit: 100
      },
      lotteryId: null,
      lastExecuted: 0,
      isExecuting: false,
      minInterval: 30000,
      executTimer: null
    };
  },
  mounted() {
    window.onresize = () => this.resizeHandler();
  },
  methods: {
    async getDanmakuInfo(lotteryId) {
      this.lotteryId = lotteryId;
      const res = await this.$http.post("lucky/lottery/latests", {
        lotteryId: this.lotteryId,
        page: this.danmakuPage.page,
        limit: this.danmakuPage.limit,
        currency: "USDT"
      });
      if (!res) {
        this.$toasted.error("res.message");
        return;
      }
      if (res.code > 0) {
        this.$toasted.error(res.message);
        return;
      }
      if (!res.data) {
        this.$toasted.error(res.message);
        return;
      }
      console.log("res", res);
      this.danmus = res.data;
      return res.data;
    },
    play(type) {
      switch (type) {
        case "play":
          this.$refs.danmaku.play();
          break;
        case "pause":
          this.$refs.danmaku.pause();
          break;
        case "stop":
          this.$refs.danmaku.stop();
          break;
        case "show":
          this.$refs.danmaku.show();
          break;
        case "hide":
          this.$refs.danmaku.hide();
          break;
        case "reset":
          this.$refs.danmaku.reset();
          break;
        default:
          break;
      }
    },
    resizeHandler() {
      if (this.executTimer) clearTimeout(this.executTimer);
      this.executTimer = setTimeout(() => {
        this.$refs.danmaku.resize();
      });
    },
    async executeDanmaku() {
      await this.getDanmakuInfo(this.lotteryId);
      this.$refs.danmaku.add(...this.danmus);
    },
    async danmakuListEnd() {
      const currentTime = Date.now();
      const timeElapsed = currentTime - this.lastExecuted;
      // 如果回调正在执行，跳过此次执行
      if (this.isExecuting) return;

      // 如果时间间隔小于 30 秒（但是小于 15 秒则延迟），则延迟执行
      if (timeElapsed < this.minInterval) {
        const remainingTime = this.minInterval - timeElapsed;
        console.log(`距离上次执行时间不到30秒，延迟${remainingTime}ms后执行`);
        setTimeout(() => this.danmakuListEnd(), remainingTime); // 延时执行回调函数
        return;
      }

      // 执行回调函数
      this.isExecuting = true; // 设置为执行中
      try {
        await this.executeDanmaku();
      } catch (error) {} finally {
        this.lastExecuted = Date.now(); // 更新上次执行时间
        this.isExecuting = false; // 标记回调执行完毕
      }
    }
  }
};