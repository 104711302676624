import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import FireworkEffect from "@assets/js/fireworkEffect.js";
const userVuex = createNamespacedHelpers("user");
import formatter from "@utils/formatter";
export default {
  name: "slotMachine",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    fmt_pre() {
      return formatter.fmt_pre;
    }
  },
  data() {
    const tabList = [{
      value: 1,
      label: "Awards"
    }, {
      value: 0,
      label: "My Records"
    }];
    return {
      slotMachineConfig: null,
      slotMachineResult: null,
      slotMachineAllInfo: null,
      tabList,
      selectTab: 1,
      slotMachineInfo: {
        stock: {
          price_stock: 0,
          price_coin: 0,
          price_tap: 0
        },
        tap: {
          price_stock: 0,
          price_coin: 0,
          price_tap: 0
        }
      },
      slotMachineNum: 3,
      rewardList: null,
      fireworkEffect: null,
      isMachineArrowAnim: false,
      isstockMachineBtnDown: false,
      istapMachineBtnDown: false,
      isSlotMachineSelect: false,
      isPopResultShow: false,
      coinResultRef: null,
      selectRow: null,
      //抽到的奖品
      isFlag: true,
      btnType: "",
      lotteryId: null,
      isInit: false,
      isstockLoading: false,
      istapLoading: false,
      myRecordList: null,
      myRecordListPage: {
        page: 1,
        limit: 50
      },
      myRecordIsLoading: false,
      myRecordIsFinished: false,
      popFailShow: false,
      isRefresh: false
    };
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    this.isPopResultShow = false;
    this.popFailShow = false;
    if (this.$refs.mDanmakuRef) {
      clearTimeout(this.$refs.mDanmakuRef.executTimer);
      this.$refs.mDanmakuRef.play("stop");
    }
    if (this.fireworkEffect) {
      this.fireworkEffect.stopFirework();
      this.fireworkEffect.destroy();
    }
    setTimeout(() => {
      next();
    }, 100);
  },
  watch: {
    selectTab: {
      async handler(newTab, oldTab) {
        if (newTab === 0) {
          window.addEventListener("scroll", this.handleScroll.bind(this));
          this.myRecordListPage.page = 1;
          const list = await this.getMyRecordList({});
          this.myRecordList = list.items;
        } else {
          window.removeEventListener("scroll", this.handleScroll);
        }
      },
      deep: true
    }
  },
  methods: {
    ...userVuex.mapMutations(["setUserData"]),
    async postSlotMachineInfo() {
      const res = await this.$http.post("/lucky/lottery/list", {
        provider: "official",
        type: 1
      });
      if (!res) {
        return;
      }
      if (res.code > 0) {
        this.lotteryId = -1;
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      if (res.data.length <= 0) {
        this.lotteryId = -1;
        return;
      }
      console.log("!res.data", !res.data, res.data.length);
      const data = res.data[0];
      let price_extras = null;
      price_extras = data?.price_extras;
      if (data.hasOwnProperty("price_extras") && typeof data.price_extras === "string") {
        price_extras = JSON.parse(data.price_extras);
      }
      this.slotMachineInfo = {
        ...data,
        stock: {
          price_stock: data.price_stock,
          price_coin: data.price_coin
        },
        tap: {
          price_tap: price_extras.hasOwnProperty("tap") ? price_extras?.tap.price_tap : 0,
          price_coin: price_extras.hasOwnProperty("tap") ? price_extras?.tap.price_coin : 0
        }
      };
      this.lotteryId = data.id;
      console.log("this.slotMachineInfo", this.slotMachineInfo, this.lotteryId);
    },
    async postSlotMachineConfig() {
      const element = ["TAPSTOCK", "TAPCOINS", "USDT", "TAP", "TAPSTOCK", "TAPCOINS", "USDT", "TAP", "CRY"];
      this.slotMachineConfig = {};
      for (let i = 0; i < this.slotMachineNum; i++) {
        this.slotMachineConfig[i] = element;
      }
      this.$nextTick(() => {
        this.isFlag = false;
        //进入页面 老虎机滚动动画
        this.$refs.mSlotMachineRef.initSpeed = 300;
        this.$refs.mSlotMachineRef.incremNum = 500;
        this.$refs.mSlotMachineRef.setConfig("init");
        this.isInit = true;
        setTimeout(() => {
          this.$refs.mSlotMachineRef.turn({
            id: 10,
            title: "50 USDT",
            icon: "",
            disp_combo: ["TAPSTOCK", "TAPSTOCK", "TAPSTOCK"],
            currency: "USDT",
            amount: 50
          });
        }, 100);
      });
    },
    async postSlotMachineResult() {
      this[`is${this.btnType}Loading`] = true;
      const res = await this.$http.post("/lucky/lottery/draw", {
        lotteryId: this.lotteryId,
        priceType: this.btnType
      });
      if (!res) {
        this[`is${this.btnType}Loading`] = false;
        return;
      }
      if (res.code > 0) {
        this.$toasted.clear();
        if (!res.message.includes("does not exist")) {
          this.$toasted.error(res.message);
        }
        this[`is${this.btnType}Loading`] = false;
        this.initFnc();
        return;
      }
      if (!res.data) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        this[`is${this.btnType}Loading`] = false;
        return;
      }
      this.slotMachineResult = res.data.winning_item;
      this.slotMachineAllInfo = {
        user: res.data.user,
        lottery: res.data.lottery,
        winning_item: res.data.winning_item
      };
      if (res.data.lottery.price_type === null || res.data.lottery.price_type === "stock") {
        this.userData.stock = this.userData.stock - this.slotMachineInfo.stock.price_stock;
        this.userData.coin = this.userData.coin - this.slotMachineInfo.stock.price_coin;
      } else if (res.data.lottery.price_type === "tap") {
        this.userData.coin = this.userData.coin - this.slotMachineInfo.tap.price_coin;
        this.$refs.nCurrencyRef.tonCurrency.tap.balance = this.$refs.nCurrencyRef.tonCurrency.tap.balance - this.slotMachineInfo.tap.price_tap;
      }
      if (this.slotMachineResult.currency === "TAPSTOCK" || this.slotMachineResult.currency === "TAPCOINS") {
        this.coinResultRef = this.$refs.nCurrencyTwoRef.$refs[`${this.slotMachineResult.currency}Ref`];
      } else {
        this.coinResultRef = this.$refs.nCurrencyRef.$refs[`${this.slotMachineResult.currency}Ref`];
      }
      this.selectRow = {
        ...this.slotMachineResult,
        value: this.slotMachineResult.amount,
        type: this.slotMachineResult.currency.toLowerCase()
      };
      this[`is${this.btnType}Loading`] = false;
    },
    //开始游戏
    async slotMachineTurn(callback) {
      if (!this.slotMachineInfo) {
        this.isFlag = true;
        return;
      }
      console.log("this.lotteryId", this.lotteryId);
      if (this.lotteryId < 0) {
        this.isFlag = true;
        this.initFnc("refresh");
        return;
      }
      await this.postSlotMachineResult();
      if (!this.slotMachineResult) {
        this.isFlag = true;
        return;
      }
      if (this.fireworkEffect) {
        this.playFirworkEffect();
      }
      this.isInit = false;
      this.$refs.mSlotMachineRef.initSpeed = 1500;
      this.$refs.mSlotMachineRef.incremNum = 1000;
      this.$refs.mSlotMachineRef.setConfig();
      callback(this.slotMachineResult);
    },
    onTab(item) {
      this.selectTab = item.value;
    },
    async getRewardList() {
      const res = await this.$http.post("/lucky/lottery/items", {
        lotteryId: this.lotteryId
      });
      if (!res) {
        return;
      }
      if (res.code > 0) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      if (!res.data) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      this.rewardList = res.data;
    },
    setFirworkEffect() {
      this.$nextTick(() => {
        const canvas = document.getElementById("fireworkCanvas");
        const leftDiv = document.getElementById("machineLeftArrowRef");
        const rightDiv = document.getElementById("machineRightArrowRef");
        this.fireworkEffect = new FireworkEffect({
          canvas: canvas,
          leftDiv: leftDiv,
          rightDiv: rightDiv,
          config: {
            numParticles: 1,
            minSpeed: 1,
            maxSpeed: 8,
            minLength: 2,
            maxLength: 8,
            flameColorMin: 20,
            flameColorMax: 40,
            spreadRange: Math.PI,
            maxParticles: 200
          },
          parentWidth: this.$refs.machineArrowsRef.offsetWidth,
          parentHeight: this.$refs.machineArrowsRef.offsetHeight
        });
      });
    },
    playFirworkEffect() {
      if (this.fireworkEffect && !this.fireworkEffect.isCreating) {
        this.isMachineArrowAnim = true;
        this.fireworkEffect.isCreating = true;
        this.fireworkEffect.startFirework();
      }
    },
    stopFirworkEffect() {
      this.isMachineArrowAnim = false;
      if (!this.fireworkEffect) return;
      this.fireworkEffect.stopFirework();
    },
    //游戏结束
    async slotMachineFinish(val) {
      if (this.isInit) {
        this.isFlag = true;
        return;
      }
      if (val == 2) {
        this.stopFirworkEffect();
        return;
      }
      //转盘结束时
      this.isSlotMachineSelect = true;
      await new Promise(resolve => setTimeout(resolve, 800));
      this.isSlotMachineSelect = false;
      //检测是否至少有一个哭脸 则显示哭脸弹窗
      const bool = this.slotMachineResult.disp_combo.some(itm => {
        return itm === "CRY";
      });
      if (bool || this.slotMachineResult.amount <= 0) {
        this.popFailShow = true;
      } else {
        this.isPopResultShow = true;
      }
    },
    onMachineBtnDown(type) {
      if (!this.isFlag) return;
      this[`is${type}MachineBtnDown`] = true;
    },
    onMachineBtnUp(type) {
      this.$nextTick(() => {
        if (this.lotteryId < 0) {
          this.initFnc("refresh");
          return;
        }
        const slotMachineInfo = this.slotMachineInfo;
        const userData = this.userData;
        const isstock = slotMachineInfo && userData && (slotMachineInfo.stock.price_stock > 0 && userData.stock < slotMachineInfo.stock.price_stock || slotMachineInfo.stock.price_coin > 0 && userData.coin < slotMachineInfo.stock.price_coin);
        if (this.slotMachineInfo && this.userData && isstock && type == "stock") {
          this.$toasted.clear();
          this.$toasted.error("Insufficient balance");
          return;
        }
        const istap = slotMachineInfo && userData && slotMachineInfo.tap.price_tap > 0 && this.$refs.nCurrencyRef.tonCurrency.tap.balance < slotMachineInfo.tap.price_tap;
        if (this.slotMachineInfo && this.userData && istap && type == "tap") {
          this.$toasted.clear();
          this.$toasted.error("Insufficient balance");
          return;
        }
        if (!this.isFlag) return;
        this.isFlag = false;
        this[`is${type}MachineBtnDown`] = false;
        this.btnType = type;
        const mSlotMachineRef = this.$refs.mSlotMachineRef;
        mSlotMachineRef.turn();
      });
    },
    onMachineBtnLeave(type) {
      if (!this.isFlag) return;
      this[`is${type}MachineBtnDown`] = false;
    },
    //更新数据
    async onAccept() {
      this.setUserData(this.slotMachineAllInfo.user);
      if (this.slotMachineAllInfo.lottery.price_type === null || this.slotMachineAllInfo.lottery.price_type === "stock") {
        this.slotMachineInfo.stock.price_stock = this.slotMachineAllInfo.lottery.price_stock;
        this.slotMachineInfo.stock.price_coin = this.slotMachineAllInfo.lottery.price_coin;
      } else if (this.slotMachineAllInfo.lottery.price_type === "tap") {
        this.slotMachineInfo.tap.price_coin = this.slotMachineAllInfo.lottery.price_coin;
        this.slotMachineInfo.tap.price_tap = this.slotMachineAllInfo.lottery.price_tap;
      }
      await this.$refs.nCurrencyRef.getCurrencyData();
      this.$refs.mDanmakuRef.executeDanmaku();
      this.myRecordListPage.page = 1;
      const list = await this.getMyRecordList({});
      this.myRecordList = list.items;
      this.slotMachineResult = null;
      this.isFlag = true;
    },
    async getMyRecordList(params) {
      const res = await this.$http.post("/lucky/lottery/logs", {
        lotteryId: this.lotteryId,
        page: this.myRecordListPage.page,
        limit: this.myRecordListPage.limit,
        ...params
      });
      if (!res) {
        this.$toasted.error("res.message");
        return;
      }
      if (res.code > 0) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      if (!res.data) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      return res.data;
    },
    async getMyRecordListFunc(isPage) {
      if (this.selectTab === 1) {
        window.removeEventListener("scroll", this.handleScroll);
        return;
      }
      this.myRecordListPage.page++;
      if (!isPage) {
        this.myRecordList = null;
        this.myRecordListPage.page = 1;
      }
      const params = {
        page: this.myRecordListPage.page,
        limit: this.myRecordListPage.limit
      };
      this.myRecordIsLoading = true;
      const res = await this.getMyRecordList(params);
      this.myRecordListPage.total = res.total;
      this.myRecordList.push(...res.items);
      this.myRecordIsFinished = this.myRecordList.length >= this.myRecordListPage.total; // 没有更多数据
      this.myRecordIsLoading = false;
    },
    async handleScroll() {
      if (this.myRecordIsLoading || this.myRecordIsFinished) return;
      // 获取页面滚动的距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      // 判断是否接近页面底部（距离底部100px以内时触发加载更多）
      if (scrollTop + windowHeight + 1 >= documentHeight) {
        await this.getMyRecordListFunc(true);
      }
    },
    async initFnc(type) {
      if (type == "refresh" && this.isRefresh) {
        return;
      }
      if (type == "refresh") {
        this.isRefresh = true;
      }
      this.slotMachineConfig = null;
      this.slotMachineResult = null;
      this.slotMachineInfo = {
        stock: {
          price_stock: 0,
          price_coin: 0,
          price_tap: 0
        },
        tap: {
          price_stock: 0,
          price_coin: 0,
          price_tap: 0
        }
      };
      this.lotteryId = -1;
      await this.postSlotMachineInfo();
      if (type == "refresh") {
        setTimeout(() => {
          if (this.lotteryId < 0) {
            this.$toasted.clear();
            this.$toasted.info("Under Maintenance!");
          }
          this.isRefresh = false;
        }, 2000);
      }
      if (this.lotteryId && this.lotteryId < 0) {
        return;
      }
      if (this.$refs.mDanmakuRef) {
        this.$refs.mDanmakuRef.getDanmakuInfo(this.lotteryId);
      }
      this.getRewardList();
      this.myRecordList = [];
      this.getMyRecordListFunc(true);
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.setWowAnim();
      this.postSlotMachineConfig();
      this.setFirworkEffect();
      window.addEventListener("scroll", this.handleScroll.bind(this));
      if (this.$refs.nCurrencyRef) {
        await this.$refs.nCurrencyRef.getCurrencyData();
      }
      this.initFnc();
    });
  }
};