var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "slotMachine"
  }, [_c('div', {
    ref: "imgsRef",
    staticClass: "slotMachineImgs",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/lucky-result-light.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-tap.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-tapcoins.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-tapstock.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-ton.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-usdt.png`)})`
    }
  }), _c('n-currency', {
    ref: "nCurrencyRef",
    attrs: {
      "isShowKey": false,
      "isShowCoin": false
    }
  }), _c('div', {
    staticClass: "slotMachine-body page-body"
  }, [_c('div', {
    ref: "machineArrowsRef",
    staticClass: "slotMachine-main page-main",
    style: {
      background: `#0a1238 url(${require(`@images/${_vm.theme}/new/machine/machine-page-bg.jpg`)}) center top/100% auto no-repeat`
    }
  }, [_vm.slotMachineInfo && _vm.lotteryId > 0 ? _c('div', {
    staticClass: "dan-maku"
  }, [_c('m-danmaku', {
    ref: "mDanmakuRef"
  })], 1) : _vm._e(), _c('canvas', {
    attrs: {
      "id": "fireworkCanvas"
    }
  }), _c('div', {
    staticClass: "machine",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-bg.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "machine-main"
  }, [_c('div', {
    staticClass: "machine-title",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-title.png`)}) center/auto 100% no-repeat`
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.slotMachineInfo && _vm.lotteryId < 0 && _vm.initFnc('refresh');
      }
    }
  }, [_c('div', {
    staticClass: "machine-title__ad"
  }), _c('span', [_vm.slotMachineInfo && _vm.lotteryId > 0 ? _c('i', {
    class: _vm.slotMachineInfo && !_vm.slotMachineInfo.hasOwnProperty('title') && 'active'
  }, [_vm._v(_vm._s(_vm.slotMachineInfo.title ? _vm.slotMachineInfo.title : "--"))]) : _vm.slotMachineInfo && _vm.lotteryId < 0 && !_vm.slotMachineInfo.hasOwnProperty('title') ? _c('i', {
    staticClass: "active"
  }, [_vm._v("Under Maintenance "), _c('svg-icon', {
    attrs: {
      "spin": _vm.isRefresh,
      "name": "icon-refresh2"
    }
  })], 1) : _vm._e()])]), _c('div', {
    staticClass: "machine-container",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-main-bg.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "machine-menu"
  }, [_c('m-slot-machine', {
    ref: "mSlotMachineRef",
    attrs: {
      "configs": _vm.slotMachineConfig,
      "results": _vm.slotMachineResult,
      "rowNum": _vm.slotMachineNum,
      "isSelect": _vm.isSlotMachineSelect
    },
    on: {
      "turn": _vm.slotMachineTurn,
      "finish": _vm.slotMachineFinish
    }
  })], 1), _c('div', {
    staticClass: "machine-arrows"
  }, [_c('div', {
    staticClass: "machine-arrow",
    class: _vm.isMachineArrowAnim && 'active',
    attrs: {
      "id": "machineLeftArrowRef"
    }
  }, [_c('span', {
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-arrow.png`)}) center/100% auto no-repeat`
    }
  })]), _c('div', {
    staticClass: "machine-arrow right",
    class: _vm.isMachineArrowAnim && 'active',
    attrs: {
      "id": "machineRightArrowRef"
    }
  }, [_c('span', {
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-arrow.png`)}) center/100% auto no-repeat`
    }
  })]), _c('div', {
    staticClass: "shadow"
  })])]), _vm.slotMachineInfo && _vm.userData ? _c('div', {
    staticClass: "machine-btns"
  }, [_c('div', {
    staticClass: "machine-btn",
    class: (!_vm.isFlag || _vm.slotMachineInfo.stock.price_stock <= 0 && _vm.slotMachineInfo.stock.price_coin <= 0 || _vm.slotMachineInfo.stock.price_stock > 0 && _vm.userData.stock < _vm.slotMachineInfo.stock.price_stock || _vm.slotMachineInfo.stock.price_coin > 0 && _vm.userData.coin < _vm.slotMachineInfo.stock.price_coin) && 'active',
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-btn2-bg.png`)}) center/auto 100% no-repeat`
    },
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onMachineBtnDown('stock');
      },
      "touchend": function ($event) {
        _vm.isMobile && _vm.onMachineBtnUp('stock');
      },
      "mousedown": function ($event) {
        !_vm.isMobile && _vm.onMachineBtnDown('stock');
      },
      "mouseleave": function ($event) {
        !_vm.isMobile && _vm.onMachineBtnLeave('stock');
      },
      "mouseup": function ($event) {
        !_vm.isMobile && _vm.onMachineBtnUp('stock');
      }
    }
  }, [_c('div', {
    staticClass: "machine-btn-main",
    class: _vm.isstockMachineBtnDown && 'active',
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-btn2-bg-1.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "machine-btn__title"
  }, [_vm._v("SPIN")]), !_vm.isstockLoading ? _c('div', {
    staticClass: "machine-btn__value",
    style: {
      background: _vm.lotteryId > 0 && (_vm.slotMachineInfo.stock.price_stock > 0 && _vm.userData.stock < _vm.slotMachineInfo.stock.price_stock || _vm.slotMachineInfo.stock.price_coin > 0 && _vm.userData.coin < _vm.slotMachineInfo.stock.price_coin) ? '#ff0808c4' : ''
    }
  }, [_vm.slotMachineInfo.stock.price_stock > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/TAPSTOCK.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v(" " + _vm._s(_vm.slotMachineInfo ? _vm.fmt_pre(_vm.slotMachineInfo.stock.price_stock) : "--"))])]) : _vm._e(), _vm.slotMachineInfo.stock.price_stock > 0 && _vm.slotMachineInfo.stock.price_coin > 0 ? [_vm._v(" + ")] : _vm._e(), _vm.slotMachineInfo.stock.price_coin > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/TAPCOINS.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v(" " + _vm._s(_vm.fmt_pre(_vm.slotMachineInfo.stock.price_coin)))])]) : _vm._e(), _vm.slotMachineInfo.stock.price_stock <= 0 && _vm.slotMachineInfo.stock.price_coin <= 0 ? _c('span', [_vm._v("--")]) : _vm._e()], 2) : _c('div', {
    staticClass: "machine-btn__loading"
  }, [_c('div', {
    staticClass: "btn-loading"
  })]), _c('div', {
    staticClass: "machine-btn-ad"
  })])]), [[_c('div', {
    staticClass: "machine-btn",
    class: (!_vm.isFlag || _vm.slotMachineInfo.tap.price_coin <= 0 && _vm.slotMachineInfo.tap.price_tap <= 0 || _vm.slotMachineInfo.tap.price_coin > 0 && _vm.userData.coin < _vm.slotMachineInfo.tap.price_coin || _vm.slotMachineInfo.tap.price_tap > 0 && _vm.$refs.nCurrencyRef.tonCurrency.tap.balance < _vm.slotMachineInfo.tap.price_tap) && 'active',
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-btn1-bg.png`)}) center/auto 100% no-repeat`
    },
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.onMachineBtnDown('tap');
      },
      "touchend": function ($event) {
        _vm.isMobile && _vm.onMachineBtnUp('tap');
      },
      "mousedown": function ($event) {
        !_vm.isMobile && _vm.onMachineBtnDown('tap');
      },
      "mouseleave": function ($event) {
        !_vm.isMobile && _vm.onMachineBtnLeave('tap');
      },
      "mouseup": function ($event) {
        !_vm.isMobile && _vm.onMachineBtnUp('tap');
      }
    }
  }, [_c('div', {
    staticClass: "machine-btn-main",
    class: _vm.istapMachineBtnDown && 'active',
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-btn1-bg-1.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "machine-btn__title"
  }, [_vm._v("SPIN")]), !_vm.istapLoading ? _c('div', {
    staticClass: "machine-btn__value",
    style: {
      background: _vm.lotteryId > 0 && _vm.slotMachineInfo && _vm.userData && (_vm.slotMachineInfo.tap.price_coin > 0 && _vm.userData.coin < _vm.slotMachineInfo.tap.price_coin || _vm.slotMachineInfo.tap.price_tap > 0 && _vm.$refs.nCurrencyRef.tonCurrency.tap.balance < _vm.slotMachineInfo.tap.price_tap) ? '#ff0808c4' : ''
    }
  }, [_vm.slotMachineInfo.tap.price_tap > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/TAP.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v(" " + _vm._s(_vm.fmt_pre(_vm.slotMachineInfo.tap.price_tap)))])]) : _vm._e(), _vm.slotMachineInfo.tap.price_tap > 0 && _vm.slotMachineInfo.tap.price_coin > 0 ? [_vm._v(" + ")] : _vm._e(), _vm.slotMachineInfo.tap.price_coin > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/TAPCOINS.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v(" " + _vm._s(_vm.fmt_pre(_vm.slotMachineInfo.tap.price_coin)))])]) : _vm._e(), _vm.slotMachineInfo.tap.price_coin <= 0 && _vm.slotMachineInfo.tap.price_tap <= 0 ? _c('span', [_vm._v("--")]) : _vm._e()], 2) : _c('div', {
    staticClass: "machine-btn__loading"
  }, [_c('div', {
    staticClass: "btn-loading"
  })]), _c('div', {
    staticClass: "machine-btn-ad"
  })])])]]], 2) : _vm._e(), _c('div', {
    staticClass: "machine-currency"
  }, [_c('n-currency-two', {
    ref: "nCurrencyTwoRef"
  })], 1)])]), _c('div', {
    staticClass: "tab"
  }, [_vm._l(_vm.tabList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tab-item",
      class: _vm.selectTab === item.value && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), _c('div', {
    staticClass: "tab-active"
  }, [_c('div', {
    staticClass: "tab-active__rect",
    style: {
      transform: `translateX(${_vm.selectTab === 0 ? '100%' : 0})`
    }
  })])], 2), [_vm.selectTab === 1 ? _c('div', {
    staticClass: "form"
  }, [_vm._m(0), _c('div', {
    staticClass: "form-body"
  }, [_vm.rewardList ? _vm._l(_vm.rewardList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "form-item"
    }, [_c('div', {
      staticClass: "form-item__title"
    }, _vm._l(item.disp_combo, function (itm, idx) {
      return _c('img', {
        key: idx,
        attrs: {
          "src": require(`@images/${_vm.theme}/new/${itm}.png`),
          "alt": ""
        }
      });
    }), 0), _c('div', {
      staticClass: "form-item__reward"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/${item.currency}.png`),
        "alt": ""
      }
    }), _vm._v(" "), _c('span', [_vm._v("+" + _vm._s(_vm._f("fmt_pre")(item.amount, 5)))])])]);
  }) : _vm._e()], 2)]) : _vm.selectTab === 0 ? _c('div', {
    staticClass: "form"
  }, [_vm._m(1), _c('div', {
    staticClass: "form-body"
  }, [_vm.myRecordList ? [_vm.myRecordList.length > 0 ? _vm._l(_vm.myRecordList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "form-item"
    }, [_c('div', {
      staticClass: "form-item__title"
    }, [item.hasOwnProperty('disp_combo') && item.disp_combo ? _vm._l(item.disp_combo, function (itm, idx) {
      return _c('img', {
        key: idx,
        attrs: {
          "src": require(`@images/${_vm.theme}/new/${itm}.png`),
          "alt": ""
        }
      });
    }) : _vm._e()], 2), _c('div', {
      staticClass: "form-item__reward"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/${item.item_currency}.png`),
        "alt": ""
      }
    }), _vm._v(" "), _c('span', [_vm._v("+" + _vm._s(_vm._f("fmt_pre")(item.item_amount, 5)))])])]);
  }) : _vm.myRecordList.length <= 0 && _vm.myRecordIsLoading === false ? _c('div', {
    staticClass: "form-empty"
  }, [_vm._v(_vm._s(_vm.$lang(`There's nothing here`)))]) : _vm._e()] : _vm.myRecordIsLoading === true ? _c('div', {
    staticClass: "form-loading"
  }, [_c('div', {
    staticClass: "btn-loading"
  })]) : _vm._e()], 2)]) : _vm._e()]], 2)]), _c('pop-reward-result-m', {
    ref: "popRewardResultRef",
    attrs: {
      "coinRef": _vm.coinResultRef,
      "show": _vm.isPopResultShow,
      "select-row": _vm.selectRow,
      "title": _vm.$lang('Congratulations on getting')
    },
    on: {
      "update:show": function ($event) {
        _vm.isPopResultShow = $event;
      },
      "accept": _vm.onAccept
    }
  }), _c('pop-fail', {
    ref: "popFailRef",
    attrs: {
      "coinRef": _vm.coinResultRef,
      "select-row": _vm.selectRow,
      "show": _vm.popFailShow
    },
    on: {
      "accept": _vm.onAccept,
      "update:show": function ($event) {
        _vm.popFailShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "form-head"
  }, [_c('span', [_vm._v("Matching symbols")]), _c('span', [_vm._v("Rewards")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "form-head"
  }, [_c('span', [_vm._v("Winning Symbols")]), _c('span', [_vm._v("Rewards")])]);
}];
export { render, staticRenderFns };