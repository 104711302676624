var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "84%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [this.setting.popadv && this.setting.popadv.id ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "adv-title"
  }, [_vm.setting.popadv.icon ? _c('img', {
    attrs: {
      "src": _vm.setting.popadv.icon
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.setting.popadv.title) + " ")]), _c('div', {
    staticClass: "adv-content"
  }, [_c('img', {
    attrs: {
      "src": _vm.setting.popadv.banner,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "btn-confirm",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.setting.popadv.btn_title) + " "), _vm.setting.popadv.btn_icon ? _c('img', {
    attrs: {
      "src": _vm.setting.popadv.btn_icon
    }
  }) : _vm._e()])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };