var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.selectRow && _vm.show ? _c('div', {
    staticClass: "pop-fail"
  }, [_vm.isPopResultAnim ? [_c('div', {
    staticClass: "shadow"
  }), _c('div', {
    staticClass: "pop-fail-main"
  }, [_c('div', {
    staticClass: "pop-fail-info"
  }, [_c('div', {
    staticClass: "pop-fail__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/CRY.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "pop-fail__label"
  }, [_vm._v("Make persistent efforts!")])]), _vm.selectRow.value > 0 ? _c('div', {
    staticClass: "pop-fail-reward"
  }, [_c('div', {
    staticClass: "pop-fail-reward__title"
  }, [_vm._v("Failure Reward")]), _c('div', {
    staticClass: "pop-fail-reward__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/${_vm.selectRow.currency}.png`),
      "alt": ""
    }
  }), _vm._v("+" + _vm._s(_vm._f("fmt_pre")(_vm.selectRow.value)))])]) : _vm._e(), _c('div', {
    staticClass: "pop-fail-btns"
  }, [_c('div', {
    staticClass: "pop-fail-btn",
    on: {
      "click": _vm.onAccept
    }
  }, [_vm._v(_vm._s(_vm.$lang("ACCEPT")))])])])] : _vm._e(), _c('div', {
    ref: "resultCoinCoincotainerRef",
    staticClass: "luckyTable-coin-coincotainer"
  })], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };