import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const airdropVuex = createNamespacedHelpers("airdrop");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
export default {
  name: "airdropInviter",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...airdropVuex.mapState(["airdropTicketInfo", "airdropInviteInfo", "airdropInviteLog", "airdropInviteLogHasMore", "airdropInviteLogLoading", "airdropInviteLogCondition"])
  },
  data() {
    return {
      shareText: '',
      scorllFlag: false
    };
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('scroll', this.checkScroll);
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    this.shareText = `Airdrop is in progress! Get your Airdrop-Pass now and start earning your share of the $TAPS ! Don’t miss this chance! https://t.me/${this.setting.botname}/app?startapp=ref_${this.userData.ref_code}-page_airdropSecond`;
    window.addEventListener('scroll', this.checkScroll);
    await this.getAirdropTickeInfo();
    await this.getAirdropInviteLog();
  },
  methods: {
    ...airdropVuex.mapMutations(["setAirdropInviteLogCondition", "setAirdropInviteLogLoading"]),
    ...airdropVuex.mapActions(['getShare', 'getAirdropInviteLog', 'getAirdropTickeInfo']),
    getShareInfo() {
      this.getShare(res => {
        this.WebApp.shareMessage(res.id);
      });
    },
    onLoadData(page) {
      this.setAirdropInviteLogCondition({
        direction: 'in',
        reason: 'invitation',
        page: page,
        limit: 10
      });
      this.getAirdropInviteLog([() => {
        this.scorllFlag = false;
      }, () => {
        this.scorllFlag = false;
      }]);
    },
    checkScroll() {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (!this.scorllFlag) {
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          // 接近底部时触发加载  
          this.scorllFlag = true;
          if (this.airdropInviteLogHasMore && !this.airdropInviteLogLoading) {
            let page = this.airdropInviteLogCondition.page;
            this.onLoadData(page + 1);
          }
        }
      }
    }
  }
};