var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "avatar pages"
  }, [_c('n-head'), _c('div', {
    staticClass: "avatar-body page-body"
  }, [_c('div', {
    staticClass: "avatar-main page-main"
  }, [_c('div', {
    staticClass: "intro"
  }, [_c('div', {
    staticClass: "intro-main"
  }, [_vm.selectBackground ? _c('div', {
    staticClass: "intro-avatar wow zoomIn",
    style: {
      background: 'url(' + require(`@images/${_vm.theme}/new/avatar/index-bg-${_vm.selectBackground}.jpg`) + ') center/auto 160% no-repeat'
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('img', {
    attrs: {
      "src": `${_vm.avatarUrl}/avatar-${_vm.selectAvatar}.png?v=1`,
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "intro-info"
  }, [_c('div', {
    staticClass: "intro-info__name"
  }, [_vm._v(_vm._s(_vm.avatarList[_vm.selectAvatar - 1].name))]), _c('div', {
    staticClass: "intro-info__text"
  }, [_vm._v(_vm._s(_vm.avatarList[_vm.selectAvatar - 1].intro))]), !_vm.isUseLoading ? _c('div', {
    staticClass: "btn-use",
    on: {
      "click": _vm.onUse
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("USE")) + " ")]) : _c('div', {
    staticClass: "btn-use"
  }, [_c('div', {
    staticClass: "circleLoading"
  })])])])]), _c('div', {
    staticClass: "tab"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tab-item",
      class: _vm.selectTab == item.value && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0), _vm.selectTab == 'avatar' ? _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.avatarList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item wow zoomIn",
      class: [_vm.selectAvatar == index + 1 && 'active', _vm.userData.avatar_url == index + 1 && 'xs'],
      attrs: {
        "data-wow-duration": "0.5s"
      },
      on: {
        "click": function ($event) {
          index <= 4 ? _vm.onSelect(index + 1) : _vm.onLockAvatar(index + 1);
        }
      }
    }, [_c('div', {
      staticClass: "item-status"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/icon-ok.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "item-main"
    }, [_c('div', {
      staticClass: "item-avatar"
    }, [_c('img', {
      attrs: {
        "src": `${_vm.avatarUrl}/avatar-${index + 1}.png?v=1`,
        "alt": ""
      }
    })])]), index > 4 ? _c('div', {
      staticClass: "item-lock"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-lock"
      }
    })], 1) : _vm._e()]);
  }), 0) : _vm._e(), _vm.selectTab == 'background' ? _c('div', {
    staticClass: "list background"
  }, _vm._l(_vm.backgroundList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      class: [_vm.selectBackground == item.skin && 'active', _vm.userData.skin == item.skin && 'xs'],
      on: {
        "click": function ($event) {
          return _vm.onBgSelect(item.skin);
        }
      }
    }, [_c('div', {
      staticClass: "item-status"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/icon-ok.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "item-main"
    }, [_vm.selectBackground ? _c('div', {
      staticClass: "item-bg",
      style: {
        background: 'url(' + require(`@images/${_vm.theme}/new/avatar/index-bg-${item.skin}.jpg`) + ') center bottom/100% auto no-repeat'
      }
    }) : _vm._e()])]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1), _c('pop-lock-avatar', {
    ref: "popLockAvatarRef",
    attrs: {
      "show": _vm.popLockAvatarShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popLockAvatarShow = $event;
      },
      "aheadFn": _vm.aheadFn
    }
  }), _c('pop-double-reward', {
    ref: "popDoubleRewardRef",
    attrs: {
      "show": _vm.popDoubleRewardShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popDoubleRewardShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };