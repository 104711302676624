import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import formatter from "@utils/formatter";
const userVuex = createNamespacedHelpers("user");
export default {
  name: "blind",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    fmt_pre() {
      return formatter.fmt_pre;
    }
  },
  data() {
    return {
      blindInfo: {
        stock: {
          price_stock: 0,
          price_coin: 0
        },
        tap: {
          price_coin: 0,
          price_tap: 0
        }
      },
      blindConfig: null,
      blindResult: null,
      blindAllInfo: null,
      lotteryId: null,
      isRefresh: false,
      coinResultRef: null,
      selectRow: null,
      //抽到的奖品
      selectBox: null,
      //选中的盒子
      isPopResultShow: false,
      myRecordList: null,
      myRecordListPage: {
        page: 1,
        limit: 50
      },
      myRecordIsLoading: false,
      myRecordIsFinished: false,
      isstockLoading: false,
      istapLoading: false,
      isFlag: true,
      btnType: "",
      blindBoxAnim: {
        step1: false,
        //变成盲合形态
        step2: false,
        //洗牌 集中
        step3: false,
        //洗牌 抖动 制造顺序打乱
        step4: false,
        //洗牌 恢复原来位置
        step5: false
      },
      centerSize: {
        left: 0,
        top: 0
      },
      popFailShow: false
    };
  },
  methods: {
    ...userVuex.mapMutations(["setUserData"]),
    calcItemLoc() {
      this.$nextTick(() => {
        const gridContainer = this.$refs.gridContainerRef;
        if (gridContainer.children.length <= 0) return;
        // 获取父容器的尺寸
        const containerWidth = gridContainer.offsetWidth;
        const containerHeight = gridContainer.offsetHeight;
        const itemContainer = gridContainer.children[0];
        const boxWidth = containerWidth / 3;
        const boxHeight = containerHeight / 3;
        for (let i = 0; i < gridContainer.children.length; i++) {
          const item = gridContainer.children[i];
          const row = Math.floor(i / 3); // 行
          const col = i % 3; // 列
          const left = col * boxWidth;
          const top = row * boxHeight;
          item.style.left = `${left}px`;
          item.style.top = `${top}px`;
          if (i == 4) {
            this.centerSize.left = left;
            this.centerSize.top = top;
          }
        }
      });
    },
    async postBlindInfo() {
      const res = await this.$http.post("/lucky/lottery/list", {
        provider: "official",
        type: 2
      });
      if (!res) {
        return;
      }
      if (res.code > 0) {
        this.lotteryId = -1;
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      console.log("res.data.length", res.data.length);
      if (res.data.length <= 0) {
        this.lotteryId = -1;
        return;
      }
      const data = res.data[0];
      let price_extras = null;
      price_extras = data?.price_extras;
      if (data.hasOwnProperty("price_extras") && typeof data.price_extras === "string") {
        price_extras = JSON.parse(data.price_extras);
      }
      this.blindInfo = {
        ...data,
        stock: {
          price_stock: data.price_stock,
          price_coin: data.price_coin,
          price_tap: 0
        },
        tap: {
          price_tap: price_extras.hasOwnProperty("tap") ? price_extras?.tap.price_tap : 0,
          price_coin: price_extras.hasOwnProperty("tap") ? price_extras?.tap.price_coin : 0
        }
      };
      this.lotteryId = data.id;
      console.log("this.blindInfo", this.blindInfo, this.lotteryId);
    },
    async postBlindConfig() {
      const res = await this.$http.post("/lucky/lottery/items", {
        lotteryId: this.lotteryId
      });
      if (!res) {
        return;
      }
      if (res.code > 0) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      if (!res.data) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      this.blindConfig = res.data.sort(() => Math.random() - 0.5);
      console.log("blindConfigblindConfig", this.blindConfig);
      this.calcItemLoc();
    },
    async postBlindResult() {
      this[`is${this.btnType}Loading`] = true;
      const res = await this.$http.post("/lucky/lottery/draw", {
        lotteryId: this.lotteryId,
        priceType: this.btnType
      });
      // const res = {
      //   code: 0,
      //   message: "",
      //   data: {
      //     lottery: {
      //       id: 1,
      //       provider: "official",
      //       title: "Crypto Spin",
      //       icon: "https://static.tapcoins.xyz/20240813/f7275f30-b77e-456d-98f4-bae5cf71c5d0.png",
      //       type: 0,
      //       price_type: null,
      //       price_coin: 7130,
      //       price_stock: 1,
      //       price_tap: 0,
      //     },
      //     winning_item: {
      //       id: 19,
      //       title: "0.002 USDT",
      //       icon: "",
      //       disp_combo: ["TAP", "USDT", "TAPSTOCK"],
      //       currency: "USDT",
      //       amount: 0.002,
      //     },
      //     user: {
      //       id: 6,
      //       tg_id: "6605438145",
      //       username: "wjm12345",
      //       firstname: "wjm123",
      //       lastname: "",
      //       head_color: "#cf5fa7",
      //       avatar_url: "2",
      //       total_reward: 0,
      //       extra_invite_count: 0,
      //       bind_wallet: "UQBaHGAc0mGxlAbnqvFQ1HBqVo5_8haeNaKcCUoJoPjIX0kb",
      //       coin: 17156854117,
      //       stock: 11955,
      //       total_stock: 3687,
      //       ref_code: "sEjGfH",
      //       user_level: 7,
      //       user_group_id: 377,
      //       power_max: 6000,
      //       power: 6000,
      //       multi_tap: 10,
      //       turbo_temp_times: 0,
      //       turbo_temp: 0,
      //       turbo_count: 3,
      //       turbo_max: 3,
      //       refill_count: 3,
      //       refill_max: 3,
      //       multi_tap_level: 9,
      //       power_level: 10,
      //       recharge_speed: 4,
      //       recharge_speed_level: 3,
      //       auto_bot: 0,
      //       bot_level: 0,
      //       skin: "basic2",
      //       joined_twitter: 1,
      //       joined_telegram: 1,
      //       joined_channel: 0,
      //       joined_discord: 1,
      //       ton_wallet: null,
      //       ton_gas_paid: 0,
      //       verified_premium: 0,
      //       verified_face: 1,
      //       verified_boost_channel: 0,
      //       verified_with_tapcoins: 0,
      //       app_center_tickets: 0,
      //     },
      //   },
      // };
      if (!res) {
        this[`is${this.btnType}Loading`] = false;
        return;
      }
      if (res.code > 0) {
        this.$toasted.clear();
        if (!res.message.includes("does not exist")) {
          this.$toasted.error(res.message);
        }
        this[`is${this.btnType}Loading`] = false;
        this.initFnc();
        return;
      }
      if (!res.data) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        this[`is${this.btnType}Loading`] = false;
        return;
      }
      this.blindResult = res.data.winning_item;
      this.blindAllInfo = {
        user: res.data.user,
        lottery: res.data.lottery,
        winning_item: res.data.winning_item
      };
      if (res.data.lottery.price_type === null || res.data.lottery.price_type === "stock") {
        this.userData.stock = this.userData.stock - this.blindInfo.stock.price_stock;
        this.userData.coin = this.userData.coin - this.blindInfo.stock.price_coin;
      } else if (res.data.lottery.price_type === "tap") {
        this.userData.coin = this.userData.coin - this.blindInfo.tap.price_coin;
        this.$refs.nCurrencyRef.tonCurrency.tap.balance = this.$refs.nCurrencyRef.tonCurrency.tap.balance - this.blindInfo.tap.price_tap;
      }
      this.coinResultRef = this.$refs.nCurrencyRef.$refs[`${this.blindResult.currency}Ref`];
      this.selectRow = {
        ...this.blindResult,
        value: this.blindResult.amount,
        type: this.blindResult.currency.toLowerCase()
      };
      console.log("this.selectRow", this.selectRow);
    },
    async getMyRecordList(params) {
      const res = await this.$http.post("/lucky/lottery/logs", {
        lotteryId: this.lotteryId,
        page: this.myRecordListPage.page,
        limit: this.myRecordListPage.limit,
        ...params
      });
      if (!res) {
        this.$toasted.error("res.message");
        return;
      }
      if (res.code > 0) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      if (!res.data) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        return;
      }
      return res.data;
    },
    async initFnc(type) {
      if (type == "refresh" && this.isRefresh) {
        return;
      }
      if (type == "refresh") {
        this.isRefresh = true;
      }
      // this.blindConfig = null;
      this.blindResult = null;
      this.blindInfo = {
        stock: {
          price_stock: 0,
          price_coin: 0
        },
        tap: {
          price_coin: 0,
          price_tap: 0
        }
      };
      this.lotteryId = -1;
      await this.postBlindInfo();
      if (type == "refresh") {
        setTimeout(() => {
          if (this.lotteryId < 0) {
            this.$toasted.clear();
            this.$toasted.info("Under Maintenance!");
          }
          this.isRefresh = false;
        }, 2000);
      }
      if (this.lotteryId && this.lotteryId < 0) {
        return;
      }
      if (this.$refs.mDanmakuRef) {
        this.$refs.mDanmakuRef.getDanmakuInfo(this.lotteryId);
      }
      await this.postBlindConfig();
      this.myRecordList = [];
      this.getMyRecordListFunc(false);
    },
    async getMyRecordListFunc(isPage) {
      this.myRecordListPage.page++;
      if (!isPage) {
        this.myRecordList = [];
        this.myRecordListPage.page = 1;
      }
      const params = {
        page: this.myRecordListPage.page,
        limit: this.myRecordListPage.limit
      };
      this.myRecordIsLoading = true;
      const res = await this.getMyRecordList(params);
      this.myRecordListPage.total = res.total;
      console.log("this.myRecordList", this.myRecordList);
      if (!this.myRecordList) {
        this.myRecordList = [];
      }
      this.myRecordList.push(...res.items);
      this.myRecordIsFinished = this.myRecordList.length >= this.myRecordListPage.total; // 没有更多数据
      this.myRecordIsLoading = false;
    },
    async handleScroll() {
      if (this.myRecordIsLoading || this.myRecordIsFinished) return;
      // 获取页面滚动的距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      // 判断是否接近页面底部（距离底部100px以内时触发加载更多）
      if (scrollTop + windowHeight + 1 >= documentHeight) {
        await this.getMyRecordListFunc(true);
      }
    },
    async startBlind() {
      console.log("this.blindInfothis.blindInfo", this.blindInfo, this.lotteryId);
      if (this.blindInfo && this.lotteryId < 0) {
        this.initFnc("refresh");
        return;
      }
      if (this.blindConfig && this.blindConfig.length <= 0) {
        return;
      }
      this.isFlag = false;
      this.selectBox = null;
      this.blindBoxAnim.step4 = false;
      this.blindBoxAnim.step1 = true; //变成盲合形态
      await new Promise(resolve => setTimeout(resolve, 400));
      this.blindBoxAnim.step5 = false;
      this.blindBoxAnim.step2 = true; //洗牌聚集
      await new Promise(resolve => setTimeout(resolve, 200));
      this.blindBoxAnim.step3 = true; //洗牌抖动
      await new Promise(resolve => setTimeout(resolve, 400));
      this.blindBoxAnim.step2 = false;
      this.blindBoxAnim.step3 = false;
      this.blindBoxAnim.step4 = true; //恢复原位置
      this.isFlag = true;
    },
    onSelectBlindItem(index, item) {
      this.selectBox = index;
      console.log(" this.selectBox", this.selectBox);
    },
    async onPrizeDraw(type) {
      if (!this.isFlag) return;
      this.isFlag = false;
      if (!this.blindInfo) {
        this.isFlag = true;
        return;
      }
      if (this.lotteryId < 0) {
        this.isFlag = true;
        this.initFnc("refresh");
        return;
      }
      const blindInfo = this.blindInfo;
      const userData = this.userData;
      const isstock = blindInfo && userData && (blindInfo.stock.price_stock > 0 && userData.stock < blindInfo.stock.price_stock || blindInfo.stock.price_coin > 0 && userData.coin < blindInfo.stock.price_coin);
      if (this.blindInfo && this.userData && isstock && type == "stock") {
        this.isFlag = true;
        this.$toasted.clear();
        this.$toasted.error("Insufficient balance");
        return;
      }
      const istap = blindInfo && userData && (blindInfo.tap.price_tap > 0 && this.$refs.nCurrencyRef.tonCurrency.tap.balance < blindInfo.tap.price_tap || blindInfo.tap.price_coin > 0 && userData.coin < blindInfo.tap.price_coin);
      if (this.blindInfo && this.userData && istap && type == "tap") {
        this.isFlag = true;
        this.$toasted.clear();
        this.$toasted.error("Insufficient balance");
        return;
      }
      if (this.selectBox === null) {
        this.isFlag = true;
        this.$toasted.clear();
        this.$toasted.info("Please select blind box!");
        return;
      }
      this.btnType = type;
      await this.postBlindResult();
      if (!this.blindResult) {
        this.isFlag = true;
        return;
      }
      this.blindConfig = this.updateBlindConfig(this.blindConfig, this.blindResult, this.selectBox);
      console.log("blindConfig22222222", this.blindConfig, this.blindResult, this.selectBox);
      await new Promise(resolve => setTimeout(resolve, 1500));
      if (this.blindResult.amount <= 0) {
        this.popFailShow = true;
      } else {
        this.isPopResultShow = true;
      }
      this.blindResult = null;
      this.blindBoxAnim.step1 = false;
      this.blindBoxAnim.step5 = true;
      this[`is${this.btnType}Loading`] = false;
    },
    updateBlindConfig(array, blindResult, selectBox) {
      let filteredArray = array.filter(item => !(item.amount === blindResult.amount && item.currency === blindResult.currency && item.id === blindResult.id));
      console.log("filteredArray before insert:", filteredArray);
      filteredArray.splice(selectBox, 0, blindResult);
      let remainingIndexes = [...Array(filteredArray.length).keys()].filter(i => i !== selectBox);
      for (let i = remainingIndexes.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let indexA = remainingIndexes[i];
        let indexB = remainingIndexes[j];
        [filteredArray[indexA], filteredArray[indexB]] = [filteredArray[indexB], filteredArray[indexA]]; // 交换
      }

      console.log("Final shuffled array:", filteredArray);
      return filteredArray;
    },
    //更新数据
    async onAccept() {
      this.setUserData(this.blindAllInfo.user);
      if (this.blindAllInfo.lottery.price_type === null || this.blindAllInfo.lottery.price_type === "stock") {
        this.blindInfo.stock.price_stock = this.blindAllInfo.lottery.price_stock;
        this.blindInfo.stock.price_coin = this.blindAllInfo.lottery.price_coin;
      } else if (this.blindAllInfo.lottery.price_type === "tap") {
        this.blindInfo.tap.price_coin = this.blindAllInfo.lottery.price_coin;
        this.blindInfo.tap.price_tap = this.blindAllInfo.lottery.price_tap;
      }
      await this.$refs.nCurrencyRef.getCurrencyData();
      this.$refs.mDanmakuRef.executeDanmaku();
      this.myRecordListPage.page = 1;
      const list = await this.getMyRecordList({});
      this.myRecordList = list.items;
      this.isFlag = true;
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.setWowAnim();
      this.calcItemLoc();
      window.addEventListener("scroll", this.handleScroll.bind(this));
      if (this.$refs.nCurrencyRef) {
        await this.$refs.nCurrencyRef.getCurrencyData();
      }
      this.initFnc();
    });
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    this.isPopResultShow = false;
    this.popFailShow = false;
    console.log("this.$refs.mDanmakuRef", this.$refs.mDanmakuRef);
    if (this.$refs.mDanmakuRef) {
      clearTimeout(this.$refs.mDanmakuRef.executTimer);
      this.$refs.mDanmakuRef?.play("stop");
    }
    setTimeout(() => {
      next();
    }, 100);
  }
};