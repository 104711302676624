import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const dailyViex = createNamespacedHelpers("daily");
const taskVuex = createNamespacedHelpers("task");
import { coinAnimFn } from "@utils/index";
export default {
  name: "sign",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...dailyViex.mapState(["steps", "tomorrowDistance"])
  },
  data() {
    return {
      lastTime: 0,
      lastTimer: null,
      lastTimeStr: '',
      popSignShow: false,
      todayItem: {},
      taskList: []
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popSignShow = false;
    if (this.lastTimer) {
      clearInterval(this.lastTimer);
    }
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    await this.getSteps([1]);
    if (this.setting && this.setting.dailyLoginTaskId) {
      await this.getSubTaskList([this.setting.dailyLoginTaskId, res => {
        this.taskList = res;
        this.foramtTaskList();
      }]);
    }
    this.steps.forEach(item => {
      if (item.today) {
        this.todayItem = item;
      }
    });
    if (!this.todayItem.completed) {
      this.$loading.show();
      await this.updatedDaily([1, 0, () => {
        this.$loading.hide();
        coinAnimFn({
          containerRef: document.getElementById("destoryCoinContainerRef"),
          ref: document.getElementById("coinRef"),
          img: require(`@images/${this.theme}/index-icon-small-coin.png`),
          radius: 300,
          numCoins: 50,
          callback: () => {}
        }, this);
      }, () => {
        this.$loading.hide();
      }]);
    }
    this.popSignShow = true;
    this.lastTime = this.tomorrowDistance;
    this.countDown();
  },
  methods: {
    ...dailyViex.mapActions(["getSteps", "updatedDaily"]),
    ...taskVuex.mapActions(['getSubTaskList']),
    sign_in() {
      this.popSignShow = true;
    },
    countDown() {
      clearInterval(this.lastTimer);
      this.lastTimer = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
          this.lastTimeStr = this.formatter.formatHour(this.lastTime);
        } else {
          clearInterval(this.lastTimer);
        }
      }, 1000);
    },
    foramtTaskList() {
      this.taskList.sort((a, b) => {
        return a.completed - b.completed;
      });
    }
  }
};