var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "container",
    staticClass: "u-danmaku"
  }, [_c('div', {
    ref: "dmContainer",
    class: ['danmus', {
      show: !_vm.hidden
    }, {
      paused: _vm.paused
    }]
  }), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };