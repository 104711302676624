var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "90%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tap.png`)
    }
  }), _vm.taskInfo ? _c('span', {
    staticClass: "reward"
  }, [_vm._v("+ " + _vm._s(_vm.taskInfo.reward_token) + " "), _vm.taskInfo.reward_token_symbol == 'TAP' ? [_vm._v(" TAPS ")] : [_vm._v(" " + _vm._s(_vm.taskInfo.reward_token_symbol) + " ")]], 2) : _vm._e()]), _vm.status != 4 ? _c('div', {
    staticClass: "title"
  }, [_vm._v("Please check your certification status")]) : _vm._e(), _vm.status == 2 || _vm.status == 3 ? _c('div', {
    staticClass: "tip"
  }, [_vm._v(" No verification results yet ")]) : _vm._e(), _vm.status == 4 ? _c('div', {
    staticClass: "desc"
  }, [_vm._v(" This face has already been verified ")]) : _vm._e(), _vm.status == 5 ? _c('div', {
    staticClass: "desc"
  }, [_vm._v(" This face has already been verified ")]) : _vm._e(), _vm.status == 6 ? _c('div', {
    staticClass: "desc"
  }, [_vm._v(" Authentication failed ")]) : _vm._e(), _c('div', {
    staticClass: "btns",
    on: {
      "click": _vm.submit
    }
  }, [_c('button', {
    staticClass: "btn",
    class: (_vm.status == 1 || _vm.status == 2 || _vm.status == 0 && _vm.startTimeLimit > 0) && 'gray'
  }, [_vm.status == 0 ? [_vm._v("Check"), _vm.startTimeLimit > 0 ? _c('span', [_vm._v("   " + _vm._s(_vm.startTimeLimit) + "s")]) : _vm._e()] : _vm._e(), _vm.status == 1 ? [_vm._v("Checking...")] : _vm._e(), _vm.status == 2 ? [_vm._v("Checking   "), _vm.lastTime > 0 ? _c('span', [_vm._v(_vm._s(_vm.lastTime) + "s")]) : _vm._e()] : _vm._e(), _vm.status == 3 || _vm.status == 6 ? [_vm._v("Verify again")] : _vm._e(), _vm.status == 4 ? [_vm._v("Thanks,I got it. ")] : _vm._e(), _vm.status == 5 ? [_vm._v("Close")] : _vm._e()], 2), _vm.status == 1 ? _c('div', {
    staticClass: "loading"
  }, [_c('round-loading', {
    attrs: {
      "color": "gray"
    }
  })], 1) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };