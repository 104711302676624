/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./bioMatrixScanInvite.vue?vue&type=template&id=d9cfbb32&scoped=true"
import script from "./bioMatrixScanInvite.vue?vue&type=script&lang=js"
export * from "./bioMatrixScanInvite.vue?vue&type=script&lang=js"
import style1 from "./bioMatrixScanInvite.vue?vue&type=style&index=1&id=d9cfbb32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9cfbb32",
  null
  
)

export default component.exports