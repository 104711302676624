import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "avatar",
  computed: {
    ...userVuex.mapState(["userData"]),
    ...walletVuex.mapState(["theme", "tonConnect"]),
    loadConfig() {
      return loadConfig;
    },
    avatarUrl() {
      return "https://static.tapcoins.app/avatar";
    }
  },
  data() {
    const avatarList = [{
      name: "Attacking warrior",
      intro: "Twice the fight, thanks to my monkey sidekick!"
    }, {
      name: "Snack Gourmet",
      intro: "Armed with a fish stick, ready to conquer the snack world!"
    }, {
      name: "Rubber Duck Defender",
      intro: "Saving the world, one rubber duck at a time!"
    }, {
      name: "Captain Meow",
      intro: "Eye patch, coins, and attitude—I’m the purrfect pirate!"
    }, {
      name: "Mummy Scientist",
      intro: "Prepping to scare or just staying warm? Who knows!"
    }
    // {
    //   name: "Alien sleepwalking cat",
    //   intro: "Plotting an “classified meow” mission with green aliens!",
    // },
    // {
    //   name: "Tribal Overlord",
    //   intro: "Bone in hand, calling the warriors of the tribe!",
    // },
    // {
    //   name: "Sushi Ninja",
    //   intro: "A sharp-eyed chef, ready to defeat with a rice ball!",
    // },
    // {
    //   name: "Pizza Party Cat",
    //   intro: "Snacking and stacking—today's feast star is me!",
    // },
    // {
    //   name: "Double Cat",
    //   intro: " Hero or villain? Both.",
    // },
    // {
    //   name: "Strawberry Cone Meow",
    //   intro: "Too sweet—even the ice cream on my head is melting!",
    // },
    // {
    //   name: "Raincoat Cat",
    //   intro: "A water battle is brewing—let’s enjoy the splash!",
    // },
    // {
    //   name: "Pink Cupid",
    //   intro: "Brainstorming and ready to fire a love spell at any moment!",
    // },
    // {
    //   name: "Dark Plushie",
    //   intro: "Button eyes—where fairy tales and thrillers meet!",
    // },
    // {
    //   name: "Circus Performer",
    //   intro: "Waving bananas and ruling the stage with laughter!",
    // },
    // {
    //   name: "Green One-Eyed Cat",
    //   intro: "A green cat in a barrel, ready to catch “dinner”!",
    // },
    // {
    //   name: "Cosmic Cow Cat",
    //   intro: "Grabbing planets—cutest creature in the universe!",
    // },
    // {
    //   name: "Sleepless Gamer",
    //   intro: " Eyes spiraling, but I’m not letting go of the controller!",
    // },
    // {
    //   name: "TV-Zoned Cat",
    //   intro: "Signal depends on luck today—time for wine and chill.",
    // },
    // {
    //   name: "Super Pirate",
    //   intro: "Smirking slyly—these treasures are all mine!",
    // },
    // {
    //   name: "Water Blaster Cat",
    //   intro: "Frog hat on, water gun ready—“Surrender now!”",
    // },
    // {
    //   name: "Bearded Gentleman",
    //   intro: "Pipe in mouth—life is all about enjoyment.",
    // },
    // {
    //   name: "Panicked Tourist",
    //   intro: "Tropical vacation… where did this crocodile come from?!",
    // },
    // {
    //   name: "Relentless Worker",
    //   intro: "Tired, sick, but still up early—work waits for no cat!",
    // },
    // {
    //   name: "Dizzy Sailor",
    //   intro: "“Captain, I’ve entered the cat illusion!”",
    // },
    // {
    //   name: "Punk Fire Cat",
    //   intro: "Who dares challenge my reign as king of rock punk?",
    // },
    // {
    //   name: "Neon DJ",
    //   intro: "Beats dropping—tonight’s party is mine!",
    // },
    // {
    //   name: "Music Geek",
    //   intro: "Coffee and Music—leaving the house isn’t an option.",
    // },
    // {
    //   name: "Sweet Bikini Cat",
    //   intro: "Beaches, sunshine, and treats—life is purrfect!",
    // },
    // {
    //   name: "Fitness fanatic",
    //   intro: "Dumbbells in paw, fur packed with muscles—no pain, no gain",
    // },
    ];

    const backgroundList = [{
      skin: "basic"
    }, {
      skin: "basic1"
    }, {
      skin: "basic2"
    }];
    const tab = [{
      label: "Avatar",
      value: "avatar"
    }, {
      label: "Background",
      value: "background"
    }];
    return {
      tab,
      selectTab: "avatar",
      selectAvatar: 1,
      isUseLoading: false,
      selectBackground: "basic",
      avatarList,
      backgroundList,
      popLockAvatarShow: false,
      popDoubleRewardShow: false,
      selectLockRow: null
    };
  },
  watch: {
    "userData.avatar_url": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.selectAvatar = newVal || 1;
      },
      deep: true,
      immediate: true
    },
    "userData.skin": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectBackground = newVal;
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.popLockAvatarShow = false;
    this.popDoubleRewardShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  methods: {
    ...userVuex.mapActions(["updateResultAvatar", ""]),
    onSelect(index) {
      this.selectAvatar = index;
    },
    onLockAvatar(index) {
      this.selectLockRow = index;
      this.popLockAvatarShow = true;
    },
    onBgSelect(skin) {
      this.selectBackground = skin;
    },
    async onUse() {
      this.isUseLoading = true;
      const res = await this.updateResultAvatar({
        avatar: this.selectAvatar,
        skin: this.selectBackground
      });
      if (!res) {
        this.isUseLoading = false;
        return;
      }
      this.isUseLoading = false;
      this.$nextTick(() => {
        this.$router.push("/");
      });
    },
    onTab(item) {
      console.log("item", item);
      this.selectTab = item.value;
    },
    async sendTrans() {
      const popLockAvatarRef = this.$refs.popLockAvatarRef;
      try {
        await this.tonWallet.sendTransaction([
          // {
          //   address: this.prepare.chainInfo.contract,
          //   amount: toNano(this.prepare.chainInfo.amount).toString(),
          //   payload: this.prepare.chainInfo.raw,
          // },
        ]);
      } catch (err) {
        popLockAvatarRef.isLoading = false;
      }
    },
    async aheadFn(select) {
      console.log(333222);
      this.$nextTick(async () => {
        const popLockAvatarRef = this.$refs.popLockAvatarRef;
        if (!popLockAvatarRef) return;
        if (popLockAvatarRef.isLoading) return;
        popLockAvatarRef.isLoading = true;
        //唤起支付
        // if (!this.tonConnect) {
        //   this.tonWallet.openOrCloseTonModal(true);
        //   this.tonWallet.ton.onStatusChange(async (walletAndwalletInfo) => {
        //     if (walletAndwalletInfo) {
        //       await this.sendTrans();
        //     }
        //   });
        // } else {
        //   await this.sendTrans();
        // }
        //交易成功,等待服务端返回状态
        // 模拟服务端成功接口
        console.log(123);
        setTimeout(() => {
          popLockAvatarRef.isLoading = false;
          this.popLockAvatarShow = false;
          this.popDoubleRewardShow = true;
        }, 5000);
      });
    }
  },
  mounted() {
    this.setWowAnim();
  }
};