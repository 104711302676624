var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "bitgetwalletScan page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Anti-bot verification")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v(" Before $TAPS Listing starts, in order to give players a better gaming experience and airdrop benefts, we encourage players to resist robots and complete anti-bot verification. ")]), _c('div', {
    staticClass: "img-box"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/scan-head.jpg`)
    }
  })]), _vm.taskInfo ? _c('div', {
    staticClass: "num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tap.png`)
    }
  }), _vm._v(" +" + _vm._s(_vm.taskInfo.reward_token) + " "), _vm.taskInfo.reward_token_symbol == 'TAP' ? [_vm._v(" TAPS ")] : [_vm._v(" " + _vm._s(_vm.taskInfo.reward_token_symbol) + " ")]], 2) : _vm._e(), _c('div', {
    staticClass: "btn-box",
    on: {
      "click": function ($event) {
        return _vm.doChecked('/bioMatrixScanDetail');
      }
    }
  }, [_c('div', {
    staticClass: "btn",
    class: (!_vm.taskInfo || _vm.taskInfo.completed_count == 100) && 'gray'
  }, [_c('span', {
    staticClass: "btn-txt"
  }, [_vm._v("Verify")]), _vm.taskInfo && _vm.taskInfo.completed_count > 0 ? _c('div', {
    staticClass: "ok"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })], 1) : _vm._e()])])]), _c('div', {
    staticClass: "invite",
    on: {
      "click": function ($event) {
        return _vm.jumpTo('/bioMatrixScanInvite', 0);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/taskimg/scan-inviter.jpg"
    }
  })]), _c('pop-bio-matrix-scan', {
    attrs: {
      "show": _vm.popBioMatrixScanShow,
      "taskInfo": _vm.taskInfo
    },
    on: {
      "update:show": function ($event) {
        _vm.popBioMatrixScanShow = $event;
      }
    }
  }), _c('pop-bio-matrix-scan-check', {
    attrs: {
      "show": _vm.popBioMatrixScanCheckShow,
      "taskInfo": _vm.taskInfo
    },
    on: {
      "update:show": function ($event) {
        _vm.popBioMatrixScanCheckShow = $event;
      }
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };