import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const lotteryVuex = createNamespacedHelpers("lottery");
const userVuex = createNamespacedHelpers("user");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "lottery",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting", "tonConnect"]),
    ...userVuex.mapState(["userData"]),
    ...lotteryVuex.mapState(["lotteryDetail"]),
    lotteryId() {
      return this.$route.query.id;
    }
  },
  data() {
    return {
      popLotteryJoinShow: false,
      lefTime: 0,
      timeLabel: "",
      timeVal: null,
      selectLotteryId: 0
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popLotteryJoinShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    lotteryId: {
      async handler(id) {
        console.log("id======", id);
        if (id > 0) {
          this.getLotteryDetail(id);
        }
      },
      deep: true,
      immediate: true
    },
    lotteryDetail: {
      async handler(newVal, oldVal) {
        if (newVal.id > 0) {
          clearTimeout(this.timeVal);
          if (this.lotteryDetail.tstatus !== 1) {
            this.lefTime = this.lotteryDetail.tstatus == 0 ? this.lotteryDetail.left_time : this.lotteryDetail.start_time;
            console.log("this.lefTime", this.lefTime);
            this.caclTime();
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {},
  methods: {
    ...lotteryVuex.mapActions(["getLotteryDetail"]),
    caclTime() {
      let hour = parseInt(this.lefTime / 3600);
      console.log(hour);
      if (hour == 0) {
        hour = "00";
      } else if (hour < 10) {
        hour = `0${hour}`;
      }
      let min = parseInt(this.lefTime % 3600 / 60);
      if (min == 0) {
        min = "00";
      } else if (min < 10) {
        min = `0${min}`;
      }
      let sec = this.lefTime % 60;
      if (sec == 0) {
        sec = "00";
      } else if (sec < 10) {
        sec = `0${sec}`;
      }
      this.timeLabel = `${hour}:${min}:${sec}`;
      this.lefTime--;
      if (this.lefTime <= 0) {
        this.getLotteryDetail(this.lotteryDetail.id);
      } else {
        this.timeVal = setTimeout(() => {
          this.caclTime();
        }, 1000);
      }
    },
    onCancel() {
      this.popLotteryJoinShow = false;
    },
    onConfirm() {
      this.popLotteryJoinShow = false;
    },
    onJoinPop() {
      if (!this.tonConnect) this.onTonConnect();else {
        this.selectLotteryId = this.lotteryDetail.id;
        this.popLotteryJoinShow = true;
      }
    },
    onDisconnect() {
      this.tonWallet.disconnect();
    },
    onTonConnect() {
      this.$nextTick(() => {
        this.tonWallet.openOrCloseTonModal(true);
      });
    }
  }
};