var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('dan-maku', _vm._b({
    ref: "danmaku",
    staticClass: "mDanmaku",
    attrs: {
      "isSuspend": ""
    },
    on: {
      "list-end": _vm.danmakuListEnd
    },
    scopedSlots: _vm._u([{
      key: "dm",
      fn: function ({
        index,
        danmu
      }) {
        return [_c('div', {
          staticClass: "danmu-item"
        }, [_c('div', {
          staticClass: "danmu-item__name"
        }, [_vm._v(_vm._s(danmu.firstname) + " " + _vm._s(danmu.lastname))]), _c('img', {
          staticClass: "img",
          attrs: {
            "src": require(`@images/${_vm.theme}/new/${danmu.item_currency.toUpperCase()}-small.png`)
          }
        }), _c('span', [_vm._v("+" + _vm._s(_vm._f("fmt_pre")(danmu.item_amount)))])])];
      }
    }]),
    model: {
      value: _vm.danmus,
      callback: function ($$v) {
        _vm.danmus = $$v;
      },
      expression: "danmus"
    }
  }, 'dan-maku', _vm.danmakuConfig, false), [_c('div')]);
};
var staticRenderFns = [];
export { render, staticRenderFns };