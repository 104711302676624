import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const assetsVuex = createNamespacedHelpers("assets");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "bitgetWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...assetsVuex.mapState(["assetsDetail", "detailHasMore", "assetsChainsList", "detailLoading", "detailCondition", "chainsInfo", "assetsInfo", "assetsList"]),
    ...userVuex.mapState(['userData'])
  },
  data() {
    return {
      //popFaceTipShow:false
    };
  },
  beforeRouteLeave(to, from, next) {
    //this.popFaceTipShow = false
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    //if(this.assetsChainsList.length == 0)
    await this.getAssetsChains();
    //if(this.assetsList.length == 0)
    await this.getAssetsList();
    let cid = this.$route.query.cid || 0;
    let id = this.$route.query.id || 0;
    if (!cid || !id) {
      this.$router.back();
      return;
    }
    if (!this.chainsInfo || !this.chainsInfo.id) {
      this.assetsChainsList.map(item => {
        if (item.id == cid) {
          this.setChainsInfo(item);
        }
      });
    }
    if (!this.assetsInfo || !this.assetsInfo.id) {
      this.assetsList.map(item => {
        if (item.id == id) {
          this.setAssetsInfo(item);
        }
      });
    }
    setTimeout(() => {
      this.onLoadData(1);
      this.withdrawAmount = this.assetsInfo.balance;
      window.addEventListener('scroll', this.checkScroll);
      console.log("1111", this.tonConnect, this.chainsInfo);
      if (this.tonConnect && this.assetsInfo.chain == 'ton') {
        this.withdrawAddress = this.tonWallet.uiAddress;
      }
    }, 500);
  },
  methods: {
    ...assetsVuex.mapActions(["getAssetsDetail", "getAssetsDetailStatus", "getAssetsChains", "getAssetsList"]),
    ...assetsVuex.mapMutations(["setDetailCondition", "setChainsInfo", "setAssetsInfo"]),
    onWithdraw(citem, item) {
      // if(!this.userData.verified_face){
      //   this.popFaceTipShow = true;
      //   return;
      // }
      this.setChainsInfo(citem);
      this.setAssetsInfo(item);
      this.$router.push(`/withdraw?cid=${citem.id}&id=${item.id}`);
    },
    onDeposit(citem, item) {
      // if(!this.userData.verified_face){
      //   this.popFaceTipShow = true;
      //   return;
      // }
      this.setChainsInfo(citem);
      this.setAssetsInfo(item);
      this.$router.push(`/depositDetail?cid=${citem.id}&id=${item.id}`);
    },
    async onLoadData(page) {
      this.setDetailCondition({
        "chain": this.assetsInfo.chain,
        "symbol": this.assetsInfo.symbol,
        "limit": 15,
        "page": page
      });
      await this.getAssetsDetail([() => {
        this.scorllFlag = false;
      }, () => {
        this.scorllFlag = false;
      }]);
    },
    checkScroll() {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (!this.scorllFlag) {
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          // 接近底部时触发加载  
          this.scorllFlag = true;
          if (this.detailHasMore && !this.detailLoading) {
            let page = this.detailCondition.page;
            this.onLoadData(page + 1);
          }
        }
      }
    },
    jumpTo() {
      this.WebApp.openLink('https://tonviewer.com/EQAebdctnt2DE6nSS3dkFHmdxHa4ml_Y8U_SShBYdGCYqj_9/jetton/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs');
    }
  }
};