import { createNamespacedHelpers } from "vuex";
import { v5 as uuidv5 } from 'uuid';
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const bioMatrixScanVuex = createNamespacedHelpers("bioMatrixScan");
export default {
  name: "bioMatrixScanDetail",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...bioMatrixScanVuex.mapState(['code', 'taskInfo', 'historyCount'])
  },
  data() {
    return {
      url: 'https://verify.biomatrix.ai/face-scan?eventCode=TAPCOINANTIBOT&channelCode=HVGZ2R&requestId=',
      uid: '',
      iframeUrl: ''
    };
  },
  beforeRouteLeave(to, from, next) {
    this.uid = '';
    this.iframeUrl = '';
    window.removeEventListener('message', this.callback);
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    window.addEventListener('message', this.callback);
    await this.getTaskInfo();
    this.uid = this.taskInfo.uid_index * Math.pow(10, 12) + parseInt(this.userData.tg_id);
    this.setCode(uuidv5(this.uid + this.setting.botname.toLowerCase(), uuidv5.DNS));
    if (!this.code) {
      this.setShowPop(true);
    }
    this.setHistoryCount(window.history.length);
    this.iframeUrl = this.url + this.code + '&uid=' + this.uid;
  },
  methods: {
    ...bioMatrixScanVuex.mapMutations(['setCode', 'setShowPop', 'setHistoryCount']),
    ...bioMatrixScanVuex.mapActions(['getTaskInfo']),
    callback(event) {
      if (event.data === 'callParentMethod') {
        let goto = this.historyCount - window.history.length - 1;
        if (goto < -1) {
          this.$router.go(goto);
        } else {
          this.$router.go(-2);
        }
      }
    }
  }
};