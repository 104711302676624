var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "blinds"
  }, [_c('div', {
    ref: "imgsRef",
    staticClass: "blindImgs",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/lucky/lucky-result-light.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-tap.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-tapcoins.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-tapstock.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-ton.png`)}),url(${require(`@images/${_vm.theme}/lucky/lucky-result-coin-usdt.png`)}),url(${require(`@images/${_vm.theme}/new/blind/bind-select.png`)}),url(${require(`@images/${_vm.theme}/new/blind/reward-select.png`)})`
    }
  }), _c('n-currency', {
    ref: "nCurrencyRef"
  }), _c('div', {
    staticClass: "blinds-body page-body"
  }, [_c('div', {
    staticClass: "blinds-main page-main",
    style: {
      background: `#0a1238 url(${require(`@images/${_vm.theme}/new/machine/machine-page-bg.jpg`)}) center top/100% auto no-repeat`
    }
  }, [_vm.blindInfo && _vm.lotteryId > 0 ? _c('div', {
    staticClass: "dan-maku"
  }, [_c('m-danmaku', {
    ref: "mDanmakuRef"
  })], 1) : _vm._e(), _c('div', {
    staticClass: "blind"
  }, [_c('div', {
    staticClass: "blind-main"
  }, [_c('div', {
    staticClass: "blind-title",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/machine/machine-title.png`)}) center/auto 100% no-repeat`
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.blindInfo && _vm.lotteryId < 0 && _vm.initFnc('refresh');
      }
    }
  }, [_c('div', {
    staticClass: "blind-title__ad"
  }), _c('span', [_vm.blindInfo && _vm.lotteryId > 0 ? _c('i', {
    class: _vm.blindInfo && !_vm.blindInfo.hasOwnProperty('title') && 'active'
  }, [_vm._v(_vm._s(_vm.blindInfo.title ? _vm.blindInfo.title : "--"))]) : _vm.blindInfo && _vm.lotteryId < 0 && !_vm.blindInfo.hasOwnProperty('title') ? _c('i', {
    staticClass: "active"
  }, [_vm._v("Under Maintenance "), _c('svg-icon', {
    attrs: {
      "spin": _vm.isRefresh,
      "name": "icon-refresh2"
    }
  })], 1) : _vm._e()])]), _c('div', {
    staticClass: "blind-container",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/blind/blind-container-bg.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "blind-container-main"
  }, [_c('div', {
    staticClass: "blind-container-box"
  }, _vm._l(3, function (_) {
    return _c('div', {
      staticClass: "blind-col",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/blind/bind-item-bg.jpg`)}) center/auto 100% no-repeat`
      }
    }, [_c('div', {
      staticClass: "blind-board",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/blind/blind-items-board.png`)}) center/auto 100% no-repeat`
      }
    })]);
  }), 0), _c('div', {
    ref: "gridContainerRef",
    staticClass: "blind-container-items",
    style: {
      '--cLeft': _vm.centerSize.left + 'px',
      '--cTop': _vm.centerSize.top + 'px'
    }
  }, [_vm.blindConfig && _vm.blindConfig.length > 0 ? _vm._l(_vm.blindConfig, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "blind-item",
      class: [_vm.blindBoxAnim.step2 && 'step2', _vm.blindBoxAnim.step3 && 'step3'],
      on: {
        "click": function ($event) {
          _vm.blindBoxAnim.step4 && _vm.blindBoxAnim.step1 && _vm.isFlag && _vm.onSelectBlindItem(index, item);
        }
      }
    }, [_c('div', {
      staticClass: "blind-item-main"
    }, [_vm.selectBox == index && _vm.blindBoxAnim.step1 && _vm.blindBoxAnim.step4 ? _c('div', {
      staticClass: "blind-img box-select",
      class: [_vm.selectBox == index && !_vm.blindResult && 'select', _vm.selectBox == index && _vm.blindResult && 'draw'],
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/blind/bind-select.png`)}) center/auto 100% no-repeat`
      }
    }) : _vm._e(), _vm.selectBox != index || _vm.blindBoxAnim.step5 ? _c('div', {
      staticClass: "blind-img box",
      class: [!_vm.blindBoxAnim.step1 && 'step1'],
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/blind/bind-box.png`)}) center/auto 100% no-repeat`
      }
    }) : _vm._e(), !_vm.blindBoxAnim.step1 ? _c('div', {
      staticClass: "blind-img card",
      class: [_vm.blindBoxAnim.step1 && 'step1'],
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/blind/${_vm.blindBoxAnim.step5 && _vm.selectBox == index ? 'reward-select' : 'reward-box'}.png`)}) center/auto 100% no-repeat`
      }
    }, [_c('div', {
      staticClass: "blind-info"
    }, [item.amount > 0 ? [_c('div', {
      staticClass: "blind__icon",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/${item.currency}.png`)}) center/auto 100% no-repeat`
      }
    }), _c('div', {
      staticClass: "blind__label"
    }, [_vm._v("+" + _vm._s(_vm._f("fmt_pre")(item.amount)))])] : [_c('div', {
      staticClass: "blind__icon",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/CRY.png`)}) center/auto 100% no-repeat`
      }
    })]], 2)]) : _vm._e()])]);
  }) : _vm._l(9, function (_) {
    return _c('div', {
      staticClass: "blind-item"
    }, [_c('div', {
      staticClass: "blind-item-main"
    }, [_c('div', {
      staticClass: "blind-img card",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/blind/reward-box.png`)}) center/auto 100% no-repeat`
      }
    })])]);
  })], 2)])]), _vm.blindInfo && _vm.selectBox === null && _vm.blindBoxAnim.step4 ? _c('div', {
    staticClass: "blind-tip"
  }, [_vm._v("Please select Blind Box!")]) : _vm._e()])]), _vm.blindInfo && _vm.userData ? _c('div', {
    staticClass: "btns"
  }, [!_vm.blindBoxAnim.step1 ? _c('div', {
    staticClass: "btn btn-start",
    class: !_vm.blindConfig && 'active',
    on: {
      "click": function ($event) {
        return _vm.startBlind();
      }
    }
  }, [_c('div', {
    staticClass: "btn-open__label"
  }, [_vm._v("Start Blind Box")])]) : [_c('div', {
    staticClass: "btn btn-open left",
    class: (!_vm.isFlag || _vm.blindInfo.stock.price_stock <= 0 && _vm.blindInfo.stock.price_coin <= 0 || _vm.blindInfo.stock.price_stock > 0 && _vm.userData.stock < _vm.blindInfo.stock.price_stock || _vm.blindInfo.stock.price_coin > 0 && _vm.userData.coin < _vm.blindInfo.stock.price_coin) && 'active',
    on: {
      "click": function ($event) {
        return _vm.onPrizeDraw('stock');
      }
    }
  }, [_c('div', {
    staticClass: "btn-open__label"
  }, [_vm._v("OPEN")]), !_vm.isstockLoading ? _c('div', {
    staticClass: "btn-open__value",
    style: {
      background: _vm.lotteryId > 0 && (_vm.blindInfo.stock.price_stock > 0 && _vm.userData.stock < _vm.blindInfo.stock.price_stock || _vm.blindInfo.stock.price_coin > 0 && _vm.userData.coin < _vm.blindInfo.stock.price_coin) ? '#ff0808c4' : ''
    }
  }, [_vm.blindInfo.stock.price_stock > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/TAPSTOCK.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v(" " + _vm._s(_vm.blindInfo ? _vm.fmt_pre(_vm.blindInfo.stock.price_stock) : "--"))])]) : _vm._e(), _vm.blindInfo.stock.price_stock > 0 && _vm.blindInfo.stock.price_coin > 0 ? [_vm._v(" + ")] : _vm._e(), _vm.blindInfo.stock.price_coin > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/TAPCOINS.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v(" " + _vm._s(_vm.fmt_pre(_vm.blindInfo.stock.price_coin)))])]) : _vm._e(), _vm.blindInfo.stock.price_stock <= 0 && _vm.blindInfo.stock.price_coin <= 0 ? _c('span', [_vm._v("--")]) : _vm._e()], 2) : _c('div', {
    staticClass: "btn-open__loading"
  }, [_c('div', {
    staticClass: "btn-loading"
  })])]), _c('div', {
    staticClass: "btn btn-open right",
    class: (!_vm.isFlag || _vm.blindInfo.tap.price_coin <= 0 && _vm.blindInfo.tap.price_tap <= 0 || _vm.blindInfo.tap.price_coin > 0 && _vm.userData.coin < _vm.blindInfo.tap.price_coin || _vm.blindInfo.tap.price_tap > 0 && _vm.$refs.nCurrencyRef.tonCurrency.tap.balance < _vm.blindInfo.tap.price_tap) && 'active',
    on: {
      "click": function ($event) {
        return _vm.onPrizeDraw('tap');
      }
    }
  }, [_c('div', {
    staticClass: "btn-open__label"
  }, [_vm._v("OPEN")]), !_vm.istapLoading ? _c('div', {
    staticClass: "btn-open__value",
    style: {
      background: _vm.lotteryId > 0 && _vm.blindInfo && _vm.userData && (_vm.blindInfo.tap.price_coin > 0 && _vm.userData.coin < _vm.blindInfo.tap.price_coin || _vm.blindInfo.tap.price_tap > 0 && _vm.$refs.nCurrencyRef.tonCurrency.tap.balance < _vm.blindInfo.tap.price_tap) ? '#ff0808c4' : ''
    }
  }, [_vm.blindInfo.tap.price_tap > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/TAP.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v(" " + _vm._s(_vm.fmt_pre(_vm.blindInfo.tap.price_tap)))])]) : _vm._e(), _vm.blindInfo.tap.price_tap > 0 && _vm.blindInfo.tap.price_coin > 0 ? [_vm._v(" + ")] : _vm._e(), _vm.blindInfo.tap.price_coin > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/TAPCOINS.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v(" " + _vm._s(_vm.fmt_pre(_vm.blindInfo.tap.price_coin)))])]) : _vm._e(), _vm.blindInfo.tap.price_coin <= 0 && _vm.blindInfo.tap.price_tap <= 0 ? _c('span', [_vm._v("--")]) : _vm._e()], 2) : _c('div', {
    staticClass: "btn-open__loading"
  }, [_c('div', {
    staticClass: "btn-loading"
  })])])]], 2) : _vm._e(), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-body"
  }, [_vm.myRecordList ? [_vm.myRecordList.length > 0 ? _vm._l(_vm.myRecordList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "form-item"
    }, [_c('div', {
      staticClass: "form-item__title"
    }, [item.item_amount > 0 ? [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/${item.item_currency}.png`),
        "alt": ""
      }
    })] : [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/CRY.png`),
        "alt": ""
      }
    })]], 2), _c('div', {
      staticClass: "form-item__reward"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/${item.item_currency}.png`),
        "alt": ""
      }
    }), _vm._v(" "), _c('span', [_vm._v("+" + _vm._s(_vm._f("fmt_pre")(item.item_amount, 5)))])])]);
  }) : _vm.myRecordList.length <= 0 && _vm.myRecordIsLoading === false ? _c('div', {
    staticClass: "form-empty"
  }, [_vm._v(_vm._s(_vm.$lang(`There's nothing here`)))]) : _vm._e()] : _vm.myRecordIsLoading === true ? _c('div', {
    staticClass: "form-loading"
  }, [_c('div', {
    staticClass: "btn-loading"
  })]) : _vm._e()], 2)])])]), _c('pop-reward-result-m', {
    ref: "popRewardResultRef",
    attrs: {
      "coinRef": _vm.coinResultRef,
      "show": _vm.isPopResultShow,
      "select-row": _vm.selectRow,
      "title": _vm.$lang('Congratulations on getting')
    },
    on: {
      "update:show": function ($event) {
        _vm.isPopResultShow = $event;
      },
      "accept": _vm.onAccept
    }
  }), _c('pop-fail', {
    ref: "popFailRef",
    attrs: {
      "coinRef": _vm.coinResultRef,
      "select-row": _vm.selectRow,
      "show": _vm.popFailShow
    },
    on: {
      "accept": _vm.onAccept,
      "update:show": function ($event) {
        _vm.popFailShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };