import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "youtube",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...taskVuex.mapState(["taskList"])
  },
  data() {
    return {
      loadConfig,
      subTaskList: [],
      popYoutubeShow: false,
      itemData: {},
      selectRow: {},
      isPopRewardResultShow: false
    };
  },
  async mounted() {
    this.taskId = this.$route.query.id;
    await this.getSubTaskList([this.taskId, res => {
      this.subTaskList = res;
    }]);
  },
  beforeRouteLeave(to, from, next) {
    this.popYoutubeShow = false;
    this.isPopRewardResultShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  methods: {
    ...taskVuex.mapActions(["getSubTaskList", "taskClick", "doSubTask"]),
    onClick(item) {
      this.itemData = item;
      this.selectRow = {
        value: item.reward,
        unit: this.setting.coin,
        type: "tapcoins"
      };
      if (this.selectRow.unit == 'TAP') {
        this.selectRow.unit = 'TAPS';
      }
      this.popYoutubeShow = true;
    },
    onAccept() {
      this.popYoutubeShow = false;
      this.isPopRewardResultShow = false;
    }
  }
};