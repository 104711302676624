import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "bitgetWallet",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...taskVuex.mapState(["taskList"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      timeVal: null,
      subTaskList: [],
      isFlag: true,
      loadingText: "...",
      popCodeFieldWalletShow: false,
      rewards: 0,
      lastTime: 0,
      lastTimer: null,
      lastTimeStr: ''
    };
  },
  beforeRouteLeave(to, from, next) {
    //this.tonWallet.disconnect();
    this.popCodeFieldWalletShow = true;
    if (this.lastTimer) {
      clearInterval(this.lastTimer);
    }
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    this.tonWallet.disconnect();
    this.taskId = this.$route.query.id;
    await this.getSubTaskList([this.taskId, res => {
      this.subTaskList = res;
      this.subTaskList.filter((item, index) => {
        if (item.title == 'CodexFieldWalletCheckIn') {
          this.lastTime = item.countdown;
          this.countDown();
        }
      });
    }]);
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...taskVuex.mapMutations(["setTaskComplete"]),
    ...taskVuex.mapActions(["getTaskList", "getSubTaskList", "doSubTask"]),
    ...userVuex.mapMutations(['setUserData']),
    onSkip(item, key) {
      let url = {
        "daily_test": "https://t.me/codexfield_test_bot/wallet?startapp=tapcoins",
        "status_test": "https://t.me/codexfield_test_bot/wallet?startapp=bnbAdventure2",
        "daily": "https://t.me/codexfieldbot/wallet?startapp=tapcoins",
        "status": "https://t.me/codexfieldbot/wallet?startapp=bnbAdventure2"
      };
      if (key) {
        // this.subTaskList.filter((itm,index)=>{
        //   if(item.id == itm.id){
        //     this.$set(this.subTaskList[index], "check", true);
        //   }
        // })
        //console.log('open');
        if (this.setting.active == "test") {
          this.WebApp.openTelegramLink(url[key + "_test"]);
        } else {
          this.WebApp.openTelegramLink(url[key]);
        }
      }
    },
    subTaskComplete(item, value) {
      if (!this.isFlag) return;
      this.isFlag = false;
      this.subTaskList.filter((itm, index) => {
        this.$set(this.subTaskList[index], "loading", false);
        if (item.id == itm.id) {
          this.$set(this.subTaskList[index], "loading", true);
        }
      });
      let t = setInterval(() => {
        if (this.loadingText.length < 3) {
          this.loadingText += ".";
        } else {
          this.loadingText = ".";
        }
      }, 500);
      this.doSubTask([item.id, "", value, data => {
        clearInterval(t);
        this.isFlag = true;
        let newUserData = {
          ...this.userData,
          ...(data && data.userInfo ? data.userInfo : data)
        };
        item.completed = 1;
        if (item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        this.setUserData(newUserData);
        this.loadTaskCount();
        this.rewards = item.reward_token;
        if (item.title == 'CodexFieldWalletCheckIn') {
          this.lastTime = data.taskInfo.countdown;
          this.countDown();
        }
        this.popCodeFieldWalletShow = true;
      }, rs => {
        clearInterval(t);
        this.isFlag = true;
        this.error = rs && rs.message;
      }]);
    },
    countDown() {
      clearInterval(this.lastTimer);
      this.lastTimer = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
          this.lastTimeStr = this.formatter.formatHour(this.lastTime);
        } else {
          clearInterval(this.lastTimer);
        }
      }, 1000);
    }
  }
};