var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/sign-calendar-ok.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(_vm._s(_vm.$lang("Login successful, I have received")))]), _vm.taskInfo && _vm.taskInfo.coins ? _c('div', {
    staticClass: "energy__award"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`)
    }
  }), _vm._v(" +" + _vm._s(_vm._f("toThousands")(_vm.taskInfo.coins)) + " ")]) : _vm._e(), _vm.taskList && _vm.taskList.length > 0 ? _c('div', {
    staticClass: "energy__list"
  }, [_c('div', {
    staticClass: "energy__list__title"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/earn-list-icon-gift.png`)
    }
  }), _vm._v(" You are lucky to have the opportunity to get more rewards ")]), _c('div', {
    staticClass: "energy__item__box"
  }, _vm._l(_vm.taskList, function (item) {
    return _c('div', {
      staticClass: "energy__item"
    }, [_c('div', {
      staticClass: "energy__item__top"
    }, [_c('div', {
      staticClass: "energy__item__left"
    }, [_c('div', {
      staticClass: "energy__item__title"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "energy__item__award"
    }, [item.reward ? _c('div', {
      staticClass: "award__coin award__item"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`)
      }
    }), _vm._v(" +" + _vm._s(_vm._f("nFormatter")(item.reward, 2)) + " ")]) : _vm._e(), item.reward_stock ? _c('div', {
      staticClass: "award__keys award__item"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/earn-key.png`)
      }
    }), _vm._v(" +" + _vm._s(_vm._f("nFormatter")(item.reward_stock, 2)) + " ")]) : _vm._e()])]), !item.completed ? _c('div', {
      staticClass: "energy__item__btn",
      class: _vm.selectItem && item.id == _vm.selectItem.id && 'check' || _vm.selectItem && item.id != _vm.selectItem.id && 'gray',
      on: {
        "click": function ($event) {
          return _vm.doTask(item);
        }
      }
    }, [_vm.selectItem && item.id == _vm.selectItem.id ? [_vm._v("Check")] : [_vm._v("Start")]], 2) : _c('div', {
      staticClass: "energy__item__ok"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })], 1)]), _vm.errorTipId == item.id ? _c('div', {
      staticClass: "energy__item__err"
    }, [_vm._v(" " + _vm._s(item.check_countdown_prompt || this.$lang('Mission not completed')) + " ")]) : _vm._e()]);
  }), 0)]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };