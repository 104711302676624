import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const mineVuex = createNamespacedHelpers("mine");
export default {
  name: "cityHead",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "taskCount", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...mineVuex.mapState(["hourEearnings"]),
    route() {
      return this.$route.path;
    },
    avatarUrl() {
      return "https://static.tapcoins.app/avatar";
    }
  },
  data() {
    return {
      popMenuShow: false
    };
  },
  methods: {
    onSkip(path, id) {
      if (path == "squad") {
        this.$router.push(`/${path}?id=${id}`);
        return;
      }
      this.$router.push(`/${path}`);
    }
  },
  mounted() {
    // this.getEarningsIncrement()
    //this.loadTaskCount();
  }
};