var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "airdropInviter page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "top-box"
  }, [_c('div', {
    staticClass: "top-box-img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-pass.png`)
    }
  })]), _c('div', {
    staticClass: "top-box-txt"
  }, [_c('div', [_vm._v("invitation Rewards")]), _vm.airdropTicketInfo ? _c('div', {
    staticClass: "txt-num"
  }, [_vm._v(_vm._s(_vm.airdropTicketInfo.totalInvitationAmount))]) : _vm._e()])]), _vm._m(0), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn-share",
    on: {
      "click": _vm.getShareInfo
    }
  }, [_vm._v("Send my exclusive invite link to friends")]), _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: _vm.shareText,
      expression: "shareText"
    }],
    staticClass: "copy"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-copy"
    }
  })], 1)]), _c('div', {
    staticClass: "record"
  }, [_c('div', {
    staticClass: "record__title"
  }, [_vm._v(_vm._s(_vm.$lang("My Commission Record")))]), _c('div', {
    staticClass: "record-list"
  }, [_vm.airdropInviteLogLoading ? [_c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)])] : _vm._e(), !_vm.airdropInviteLog ? [_c('div', {
    staticClass: "empty"
  }, [_vm._v(_vm._s(_vm.$lang("There's nothing here")))])] : _vm._l(_vm.airdropInviteLog, function (item, index) {
    return _c('div', {
      staticClass: "record-item"
    }, [_c('div', {
      staticClass: "record-item__icon"
    }, [item.from_user ? [_c('m-head', {
      attrs: {
        "name": item.from_user,
        "bgColor": item.from_user.head_color
      }
    })] : _vm._e()], 2), _c('div', {
      staticClass: "record-item__mid"
    }, [item.from_user ? _c('div', {
      staticClass: "record-item__name"
    }, [_vm._v(" " + _vm._s(item.from_user.firstname) + " " + _vm._s(item.from_user.lastname) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "record-item__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/airdrop-pass.png`)
      }
    }), _vm._v(" +" + _vm._s(_vm._f("toThousands")(item.amount)) + " ")])]);
  })], 2)])]), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "desc-box"
  }, [_c('div', {
    staticClass: "desc-box-txt"
  }, [_vm._v(" When friends click on my invitation linkto get the airdrop-pass,l can get anadditlonal 20% commission reward ")])]);
}];
export { render, staticRenderFns };