import { createNamespacedHelpers } from "vuex";
import { coinAnimFn } from "@utils/index";
const walletVuex = createNamespacedHelpers("wallet");
const dailyVuex = createNamespacedHelpers("daily");
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "popSign",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...taskVuex.mapState(["task"])
  },
  watch: {
    "$parent.popSignShow": {
      handler(bool) {
        if (bool) {
          this.lockTask = false, this.selectItem = null, this.timeVal = null, this.leftTime = 0, this.isContinue = false, this.errorTipId = null;
        } else {
          clearTimeout(this.timeVal);
        }
      }
    },
    taskInfo: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    },
    taskList: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {}
    },
    taskList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      lockTask: false,
      selectItem: null,
      timeVal: "",
      leftTime: 0,
      isContinue: false,
      errorTipId: null
    };
  },
  methods: {
    ...dailyVuex.mapActions(["getSteps", "updatedDaily"]),
    ...userVuex.mapMutations(['setUserData']),
    ...taskVuex.mapActions(["doSubTask"]),
    doTask(item) {
      if (this.selectItem) {
        if (this.selectItem && item.id == this.selectItem.id) {
          if (this.leftTime <= 0) {
            this.doSubTask([item.id, '', '', rs => {
              item.completed = 1;
              this.selectItem = null;
              this.$parent.foramtTaskList();
              let newUserData = {
                ...this.userData,
                ...(rs.userInfo ? rs.userInfo : rs)
              };
              this.setUserData(newUserData);
              coinAnimFn({
                containerRef: document.getElementById("destoryCoinContainerRef"),
                ref: document.getElementById("coinRef"),
                img: require(`@images/${this.theme}/index-icon-small-coin.png`),
                radius: 300,
                numCoins: 50,
                callback: () => {}
              }, this);
            }]);
          } else {
            this.isContinue = true;
            //this.leftTime = this.selectItem.secondary_check_countdown || 10
            this.errorTipId = item.id;
            this.leftTime = 0;
            clearTimeout(this.timeVal);
            this.selectItem = null;
          }
        }
      } else {
        this.errorTipId = null;
        this.selectItem = item;
        if (!this.selectItem.check_countdown) {
          this.selectItem.check_countdown = this.selectItem.verifiable ? 5 : 15;
        }
        if (!this.selectItem.check_countdown_prompt) {
          this.selectItem.check_countdown_prompt = this.$lang('Mission not completed');
        }
        this.leftTime = this.selectItem.check_countdown || 5;
        if (this.selectItem.url.indexOf("t.me") === -1) this.WebApp.openLink(this.selectItem.url);else this.WebApp.openTelegramLink(this.selectItem.url);
        this.countDown();
      }
    },
    countDown() {
      clearTimeout(this.timeVal);
      this.leftTime--;
      if (this.leftTime > 0) {
        this.timeVal = setTimeout(() => {
          this.countDown();
        }, 1000);
      }
    }
  }
};