import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const stop = body => body.style.overflow = "";
const move = body => body.style.overflow = ""; //出现滚动条
import { coinAnimFn } from "@utils/index";
export default {
  name: "popRewardResult",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  props: {
    selectRow: null,
    //{value, unit, type} value: 值 仅UI显示  unit: 单位 仅UI显示 type:类型 tapcoins|usdt|ton
    show: null,
    //
    title: null,
    //标题
    coinRef: null //金币动画终点dom
  },

  data() {
    return {
      isPopResultAnim: false //奖品结果弹窗页内容动画状态
    };
  },

  watch: {
    show(bool) {
      this.setPopResultZindex(bool);
      if (bool) {
        setTimeout(() => {
          this.isPopResultAnim = true;
        }, 200);
      } else {
        this.isPopResultAnim = false;
      }
    }
  },
  methods: {
    setPopResultZindex(bool) {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        if (bool) {
          stop(body);
          if (body.append) {
            body.append(this.$refs.popResultRef);
          }
        } else {
          move(body);
        }
      });
    },
    onAccept() {
      this.$emit("update:show", false);
      if (!this.coinRef) {
        this.$emit("accept");
        this.showGetReward(this.selectRow.type);
        return;
      } else {
        this.coinAnimFn(this.selectRow.type);
      }
    },
    //金币位移动画
    coinAnimFn(type) {
      let radius = 80;
      let numCoins = 30;
      let count = 0;
      let src = require(`@images/${this.theme}/lucky/${type}.png`);
      this.$nextTick(() => {
        const coinContainer = this.$refs.resultCoinCoincotainerRef;
        coinContainer.innerHTML = "";
        let coins = [];
        for (let i = 0; i < numCoins; i++) {
          let coin = document.createElement("img");
          coin.src = src;
          coin.classList.add("luckyCoin");
          let angle = Math.random() * 2 * Math.PI;
          let r = Math.sqrt(Math.random()) * radius;
          let x = radius + r * Math.cos(angle) - 25;
          let y = radius + r * Math.sin(angle) - 25;
          coin.style.left = "50%";
          coin.style.top = "50%";
          coinContainer.appendChild(coin);
          coins.push({
            element: coin,
            finalX: x,
            finalY: y
          });
        }
        void coinContainer.offsetWidth;
        coins.forEach((coin, index) => {
          coin.element.style.animation = `moveToPosition 500s ease forwards`;
          coin.element.style.left = `${coin.finalX}px`;
          coin.element.style.top = `${coin.finalY}px`;
        });
        setTimeout(() => {
          let endButton = this.coinRef;
          let endButtonRect = endButton.getBoundingClientRect();
          let containerRect = coinContainer.getBoundingClientRect();
          let endX = endButtonRect.left - containerRect.left;
          let endY = endButtonRect.top - containerRect.top;
          coins.forEach((coin, index) => {
            setTimeout(() => {
              coin.element.style.left = `${endX}px`;
              coin.element.style.top = `${endY}px`;
              coin.element.addEventListener("transitionend", () => {
                endButton.classList.add("luckyShake");
                setTimeout(() => {
                  endButton.classList.remove("luckyShake");
                }, 10);
              });
              if (index === coins.length - 1) {
                coin.element.addEventListener("transitionend", () => {
                  count++;
                  coinContainer.innerHTML = "";
                  if (count == 1) this.$emit("accept");
                });
              }
            }, index * 25);
          });
        }, 500);
      });
    },
    showGetReward(type) {
      let image = require(`@images/${this.theme}/lucky/${this.selectRow.type}.png`);
      this.$nextTick(() => {
        coinAnimFn({
          containerRef: document.getElementById("destoryCoinContainerRef"),
          ref: document.getElementById("coinRef"),
          img: image,
          radius: 300,
          numCoins: 50,
          callback: () => {}
        }, this);
      });
    }
  }
};