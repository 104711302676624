import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const achievementsVuex = createNamespacedHelpers("achievements");
export default {
  name: "achievements",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...achievementsVuex.mapState(["categoryList", "taskList"])
  },
  data() {
    return {
      currentIndex: 0,
      selectItem: {},
      popEnergyShow: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popEnergyShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    if (this.categoryList.length == 0) {
      await this.getCategoryList();
    }
    if (this.taskList.length == 0) {
      await this.getTaskList([]);
    }
  },
  methods: {
    ...achievementsVuex.mapActions(['getTaskList', "getCategoryList"]),
    scrollToSection(index, sectionId) {
      //this.currentIndex = index
      const element = document.getElementById('a' + sectionId);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    handleScroll(event) {
      const content = event.target;
      this.categoryList.forEach((section, index) => {
        const sectionElement = this.$refs.sections[index];
        if (sectionElement && content.scrollTop >= sectionElement.offsetTop - 50) {
          this.currentIndex = index;
        }
      });
    },
    openView(item, index) {
      //if(item.status == 1){
      this.selectItem = item;
      this.selectItem.itemIndex = index;
      this.popEnergyShow = true;
      //}
    }
  }
};